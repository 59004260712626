<template>
    <div class="box p-3 pb-2 mb-3">
        <div class="row">
            <div class="col">
                <p class="mb-3">
                    <strong class="d-block">Data</strong>
                    {{ date }}
                </p>
            </div>
            <div class="col-12">
                <p class="mb-3">
                    <strong class="d-block">Vendedor</strong>
                    {{ seller.name }}
                </p>
            </div>
            <div class="col-12">
                <p class="mb-3">
                    <strong class="d-block">E-mail</strong>
                    <a :href="`mailto:${seller.email}`">{{ seller.email }}</a>
                </p>
            </div>
            <div class="col-12">
                <p class="mb-3">
                    <strong class="d-block">Telefone</strong>
                    <a :href="`callto:${seller.phone}`">{{ seller.phone }}</a>
                </p>
            </div> 
        </div>
    </div>
</template>

<script>
import { useGlobalStore } from '@/store/global';
export default {
    data(){
        return {
            global: useGlobalStore()
        }
    },
    props: {
        info:{
            required: true,
        },
        seller:{
            required: true,
        },
    },
    computed: {
        date() {
            return this.global.getFormatDateLong(this.info.date);
        }
    }
};
</script>

<style scoped>
.box p strong {
    font-weight: 600;
    font-size: 15px;
}

div p a {
    text-decoration: underline !important;;
}
</style>