<template>
    <TheBaseLogin
    :title="title"
    :subtitle="subtitle"
    >
        <div  v-if="!forgotPasswordCode">
            <form @submit.prevent="forgotPassword">
                <BaseInput
                    label="E-mail"
                    type="email"
                    id="email"
                    v-model="inputs.email"
                    :page="page"
                    login
                />

                <BaseButton
                    class="mt-4 btn-primary w-100"
                    :loading="isLoading"
                    :disabled="isLoading"
                    >Recuperar
                </BaseButton>
            </form>

            <p class="spacer text-center mt-5">
                <span>ou</span>
            </p>

            <BaseButton class="mt-2 btn-secondary w-100" to="/login" link>Voltar</BaseButton>

        </div>

        <form v-else @submit.prevent="resetPassword">
            <div class="loader_body" v-if="loadingPage">
                <div></div>
            </div>

            <div v-else>
                <BaseInput
                    label="E-mail"
                    type="email"
                    id="email"
                    v-model="inputs.email"
                    :page="page"
                    login
                />

                <BaseInput
                    label="Nova senha"
                    type="password"
                    id="password"
                    v-model="inputs.password"
                    :page="page"
                    login
                />

                <BaseInput
                    label="Repita a nova senha"
                    type="password"
                    id="password_confirmation"
                    v-model="inputs.password_confirmation"
                    :page="page"
                    login
                />

                <BaseButton
                    class="mt-4 btn-primary w-100"
                    :loading="isLoading"
                    :disabled="isLoading"
                    >Redefinir senha
                </BaseButton>
            </div>
        </form>

    </TheBaseLogin>
</template>

<script setup>
import TheBaseLogin from "@/components/layout/login/TheLoginBase";
import { ref, onMounted, computed } from "vue";

import { useAuthStore } from "@/store/auth";
const auth = useAuthStore();

import { useGlobalStore } from "@/store/global";
const global = useGlobalStore();

import { useRoute } from "vue-router";
const route = useRoute();

const page = ref('forgotPassword');
const loadingPage = ref(true);
const forgotPasswordCode = computed(function () {
    return route.params.code;
});

const title = ref();
const subtitle = ref();

if(forgotPasswordCode.value) {
    title.value = 'Redefinição senha';
    subtitle.value = 'Digite seu e-mail e uma nova senha.';
}else {
    title.value = 'Recuperar senha';
    subtitle.value = 'Digite seu e-mail para iniciar.';
}

onMounted(() => {
    delete global.errors[page.value];
    if(forgotPasswordCode.value) {
        auth.tryCheckResetPasswordCode(forgotPasswordCode.value).then(() => {
            loadingPage.value = false;
        });
    }else {
        loadingPage.value = false;
    }
});

const inputs = ref({
    email: '',
    password: '',
    password_confirmation: '',
    recaptcha_token: ''
});

const isLoading = ref(false);
const emailPattern = /^([\w.*-]+@([\w-]+\.)+[\w-]{2,4})?$/;


async function forgotPassword() {
    isLoading.value = false;    

    if (inputs.value.email === "" || !inputs.value.email.match(emailPattern) ) {
        global.tryLocalErrors('email', 'Digite um e-mail válido', page.value);
        return;
    }
    // eslint-disable-next-line
    grecaptcha.ready(() => {
        isLoading.value = true;
        // eslint-disable-next-line
        grecaptcha.execute(window.RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {  
            inputs.value.recaptcha_token = token;  
            auth.tryRequestResetPassword(page.value, inputs.value).then(() =>{
                isLoading.value = false;
                inputs.value.email = '';
                inputs.value.recaptcha_token = '';
            });
        });
    });
}

async function resetPassword() {
    isLoading.value = false;

    let errors = false;

    if (inputs.value.email === "" || !inputs.value.email.match(emailPattern) ) {
        global.tryLocalErrors('email', 'Digite um e-mail válido', page.value);
        errors = true;
    }

    if(!inputs.value.password) {
        global.tryLocalErrors('password', 'Campo obrigatório', page.value);
        errors = true;
    }

    if (inputs.value.password.length < 5) {
        global.tryLocalErrors('password', 'Senha deve conter pelo menos 6 caracteres', page.value);
        errors = true;
    }

    if(!inputs.value.password_confirmation) {
        global.tryLocalErrors('password_confirmation', 'Campo obrigatório', page.value);
        errors = true;
    }

    if(inputs.value.password != inputs.value.password_confirmation) {
        global.tryLocalErrors('password', 'As senhas não conferem', page.value);
        errors = true;
    }

    if(!errors) {
        // eslint-disable-next-line
        grecaptcha.ready(() => {
            isLoading.value = true;
            // eslint-disable-next-line
            grecaptcha.execute(window.RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {  
                inputs.value.recaptcha_token = token;
                const params = inputs.value;
                auth.tryResetPassword(page.value, params, forgotPasswordCode.value).then(() =>{
                    isLoading.value = false;
                });
            });
        });
    }
}
</script>

<style scoped>
form {
    position: relative;
}

.loader_body {
    position: absolute;
    margin-left: -20px;
}
</style>