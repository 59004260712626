<template>
    <div v-if="tracking" class=" mb-3">
        <div class="row box" v-if="tracking.url">
            <BaseButton @click="toggleView" class="btn-primary btn-lg">Rastrear Pedido</BaseButton>
            <div id="trackingDiv" class="toggleShow">
                <div class="col">
                    <p class="mb-1">
                        <strong class="d-block">Rastreio:</strong>
                        <a :href="tracking.url">{{ tracking.url }}</a>
                    </p>
                </div>
                <div class="col" v-if="tracking.code">
                    <p class="mb-1">
                        <strong class="d-block">Código de rastreio:</strong>
                        <a :href="tracking.code">{{ tracking.code }}</a>
                    </p>
                </div> 
            </div>                   
        </div>

        <div class="row box py-3" v-else>
            <div class="col">
                <p class="mb-1">
                    <strong class="d-block">Rastreio:</strong>
                    Rastreamento ainda não dísponível.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tracking: {
            required: true,
        }
    },
    methods:{
        toggleView(){
            var trackingDiv = document.querySelector('#trackingDiv')
            trackingDiv.classList.toggle('toggleShow');
        }
    }
}
</script>

<style scoped>

.box{
    margin: 0px 0px;
}

.toggleShow{
    display: none !important;
}

.btn-primary {
    width: 100%;
}

</style>
