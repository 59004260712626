<template>
    <template v-if="isLoading === false">
        <div v-if="route.meta.requiresAuth == true" class="mhc-100">
            <router-view></router-view>
        </div>
        <TheFooter  v-if="route.meta.requiresAuth == true" />
        <router-view v-else></router-view>
        <div id="loader_body" class="loader_body"><div></div></div>
    </template>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import TheFooter from "@/components/layout/includes/TheFooter";
import { useRoute } from "vue-router";
import { useGlobalStore } from '@/store/global';
const route = useRoute();
const globalStore = useGlobalStore();

const isLoading = ref(true);

onBeforeMount(() =>{
    const root = document.querySelector(':root');

    globalStore.tryDomain().then(function(success){
        if(success){
            root.style.setProperty('--domain_btn_bg', globalStore.domain_style.color);
            root.style.setProperty('--domain_btn_border_color', globalStore.domain_style.color);
            if(globalStore.domain_style.title === 'Fullsale'){
                root.style.setProperty('--domain_btn_color', '#FFFFFF');
                root.style.setProperty('--domain_btn_hover', '#2750A3');
            } else if(globalStore.domain_style.title === 'Evemaster') {
                root.style.setProperty('--domain_btn_color', '#ffffff');
                root.style.setProperty('--domain_btn_hover', '#0d2e21');
                document.querySelector("link[rel~='icon']").href = window.location.origin + '/favicon_evemaster.png';
            }
        } else{
            let url = window.location.hostname.split('.')
            if(url[1] == 'fullsale'){
                root.style.setProperty('--domain_btn_bg', "#1C56C6");
                root.style.setProperty('--domain_btn_border_color', "#1C56C6");
                root.style.setProperty('--domain_btn_color', '#FFFFFF');
                root.style.setProperty('--domain_btn_hover', '#2750A3');
            } else if(url[1] == 'evemaster'){
                root.style.setProperty('--domain_btn_bg', "#144331");
                root.style.setProperty('--domain_btn_border_color', "#144331");
                root.style.setProperty('--domain_btn_color', '#ffffff');
                root.style.setProperty('--domain_btn_hover', '#0d2e21');
                document.querySelector("link[rel~='icon']").href = window.location.origin + '/favicon_evemaster.png';
            }
        }
        isLoading.value = false;
    })
})

</script>


<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;200;300;400;500;700&display=swap");

.mhc-100 {
    min-height: 100%;
    padding-bottom: 53px;
}

body {
    font-family: "Poppins", "Roboto", sans-serif !important;
    font-size: 14px !important;
    color: #595959 !important;
    font-weight: 400 !important;
}

html,body,#app {
    height: 100%;
}

:root{
    --domain_btn_bg: ;
    --domain_btn_color: ;
    --domain_btn_hover: ;
    --domain_btn_border_color: ;
}

.form-control {
    color: #495057;
    font-family: "Poppins", "Roboto", sans-serif;
}

a {
    text-decoration: none !important;
    color: #215eda !important;
}

a:hover {
    text-decoration: underline !important;
}

.badget {
    padding: 2px 8px;
    font-size: 12px;
    background-color: #d7d7d7;
    color: #fff;
    border-radius: 25px;
    white-space: nowrap;
    position: relative;
    font-weight: 500;
}

.loader_body {
    display: none;
    z-index: 9999999;
    width: 35px;
    height: 35px;
    position: fixed;
    left: 50%;
    top: 0;
    border-radius: 50px;
    margin-top: 15px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 30%);
}

.loader_body div {
    display: inline-block;
    z-index: 2000;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    border: solid 3px transparent;
    border-top-color: #3cb6cc !important;
    border-left-color: #3cb6cc !important;
    border-radius: 100%;
    padding: 10px;
    -webkit-animation: pace-spinner 500ms linear infinite;
    -moz-animation: pace-spinner 500ms linear infinite;
    -ms-animation: pace-spinner 500ms linear infinite;
    -o-animation: pace-spinner 500ms linear infinite;
    animation: pace-spinner 500ms linear infinite;
}

@keyframes pace-spinner {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(130deg);
        border-top-color: #215eda;
        border-left-color: #215eda;
    }
    100% {
        transform: rotate(360deg);
        border-top-color: #3cb6cc;
        border-left-color: #3cb6cc;
    }
}
.opt-box {
    width: 100%;
    margin: 0;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    padding: 0 !important;
}
.otp-input {
    height: 40px;
    border-radius: 8px;
    border: 2px #bfbfbf solid;
    text-align: center;
    width: 100%;
    color: #215eda;
    font-weight: 500;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
    border-color: #215eda;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.opt-box > div > div {
    margin-right: 6px;
}

.opt-box > div > div:last-child {
    margin-right: 0px;
}

.row_right {
    width: 100px;
    white-space: nowrap;
}

strong {
    font-weight: 600 !important;
}

.btn-primary {
    padding:8px 70px 9px 70px;
}

.btn.btn-primary, a.btn-primary {
    background-color: var(--domain_btn_bg) !important;
    color: var(--domain_btn_color)!important;
    border-color: var(--domain_btn_border_color)!important;
}

.btn.btn-primary:hover, a.btn-primary:hover {
    background-color: #2750a3 !important;
    background-color: var(--domain_btn_hover) !important;
}

.domain_svg{
    fill: var(--domain_btn_bg)!important;
}

table {
    margin: 0 !important;
}

table td, table th {
    padding: 0.8rem 1rem !important;
    vertical-align: middle;
    color: #595959;
    border-radius: 20px;
    border: 0;
}

table tbody, table tfoot {
    border: 0 !important;
}

table tbody tr, table tfoot  {
    border-top:1px #d7d7d7 solid !important;
}

table tfoot td {
    font-weight: 600;
    border: 0;
}

.cart {
    width: 20px;
    fill: #215eda;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style:-ms-autohiding-scrollbar
}

.box {
    border: 1px solid #d7d7d7;
    border-radius: 20px;
    color: #545454;
}
.btn-lg {
    padding: 8px 70px 9px 70px !important;
}

@media screen and (max-width: 1024px){
    .dialog_close {
        display: block;
        position: absolute;
        right: 15px;
        top: 17px;
        background: var(--domain_btn_bg);
        color: var(--domain_btn_color);
        width: 43px;
        text-align: center;
        padding: 2px 5px;
        border-radius: 18px;
    }
}

</style>
