<template>
  <qrcode-vue :value="text" :size="size" level="L" render-as="svg" />
</template>
<script>
import QrcodeVue from 'qrcode.vue'

export default {
    props: {
        text: {
            type: String,
            required: false,
            default: "",
        },

        size: {
            type: Number,
            required: false,
            default: 200,
        }
    },
    components: {
        QrcodeVue,
    }
}
</script>