<template>
    <div class="h-100 d-flex flex-column">
        <div class="row justify-content-center align-items-center">
            <div class="col-md-6 login-box">
                <div class="text-center mb-3">
                    <TheLogo id="logo"/>
                    <h5>{{ title }}</h5>
                    <p>{{ subtitle }}</p>
                </div>
                <slot></slot>
            </div>
        </div>
        <footer class="text-center">
            <a target="_blank" href="https://fullsale.com.br/termos-de-uso"
                >Termos de uso</a
            >
            <a
                target="_blank"
                href="https://fullsale.com.br/politica-de-privacidade"
                >Política de privacidade</a
            >
            <a>© {{ currentYear }} Fullsale.</a>
        </footer>
    </div>
</template>

<script>
export default {
    props: [
        'title', 'subtitle'
    ],
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    }
}
</script>


<style scoped>
.row {
    width: 100%;
    height: 100%;
    margin: 0;
    color: #595959;
}

:slotted(p) {
    font-size: 14px;
}

.login-box {
    width: 500px;
    padding: 32px;
}

.login-box .form-control {
    margin-bottom: 10px;
}

#logo {
    width: 150px;
    padding: 20px 0px;
}

.login-box h5 {
    color: #2b2b2b;
}

.login-box p {
    color: #828282;
}

a {
    color: #215eda;
    text-decoration: none;
    font-size: 14px;
}
a:hover {
    color: #0056b3;
    text-decoration: underline;
}

:slotted(.spacer) {
    border-top: 1px solid #d7d7d7;
    margin-bottom: 1rem;
}

:slotted(.spacer span) {
    position: relative;
    background-color: #fff;
    top: -12px;
    display: inline-block;
    padding: 0px 20px;
}

footer {
    padding-top: 20px;
    padding-bottom: 5px;
    margin-bottom: 1rem;
}

footer a {
    font-size: 13px;
    color: #949494 !important;
    margin: 0 10px;
}

@media screen and (max-width: 768px) {
    footer a {
        font-size: 11px;
        margin: 0px 5px;
    }
    .login-box {
        padding: 25px;
    }
}
</style>