<template>
    <BaseDialog>
        <div v-if="!isLoading && order.id">
            <HeaderOrder :order="order" :backTo="'/order/' + order.order_key" />
            <InfoOrder
                :info="order.info"
                :seller="order.seller"
            ></InfoOrder>

            <TableOrder
                v-if="order.products"
                :products="order.products" 
                :amount="order.amount" 
                :shipping="order.shipping"
                :discount="order.discount"
            ></TableOrder>

            <div class="box">
                <div class="row">
                    <div class="col-md">
                        <div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th><h6 class="mb-0">Precisa de ajuda com essa compra?</h6></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><a target="_blank" href="https://fullsale.com.br/blog/como-acessar-a-area-de-membros-fullsale">Como acessar a área de membros Fullsale?</a></td>
                                    </tr>
                                    <tr>
                                        <td><a target="_blank" href="https://fullsale.com.br/blog/como-acessar-meu-curso-online">Como acessar meu curso online?</a></td>
                                    </tr>
                                    <tr>
                                        <td><a target="_blank" href="https://fullsale.com.br/blog/como-saber-o-formato-do-produto-que-comprei">Como saber o formato do produto que comprei?</a></td>
                                    </tr>
                                    <tr>
                                        <td><a target="_blank" href="https://fullsale.com.br/blog/tenho-duvidas-sobre-o-produto-que-comprei-o-que-fazer">Tenho dúvidas sobre o produto que comprei. O que fazer?</a></td>
                                    </tr>
                                    <tr>
                                        <td><a target="_blank" href="https://fullsale.com.br/blog/quais-as-garantias-para-os-produtos">Quais as garantias para os produtos?</a></td>
                                    </tr>
                                    <tr v-if="order.status.slug == 'paid'">
                                        <td v-if="order.refundable === true"><router-link :to="`/order/` + order.order_key + `/refund-request`">Solicitar reembolso</router-link></td>
                                        <td v-else><a style="color: grey !important; user-select: none" @click="runToast(order.refundable)">Solicitar reembolso</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </BaseDialog>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import HeaderOrder from "@/components/layout/order/HeaderOrder";
import InfoOrder from '@/components/layout/order/InfoOrder';
import TableOrder from '@/components/layout/order/TableOrder';

import { useRoute } from "vue-router";
const route = useRoute();
const order_key = computed(function () {
    return route.params.code;
});

import { useOrderStore } from "@/store/order";
const orders = useOrderStore();

import { useGlobalStore } from "@/store/global";
const global = useGlobalStore();

const isLoading = ref(true);

onMounted(() => {
    global.loader('show');
    if (orders.currentOrder && orders.currentOrder.order_key == order_key.value) {
        isLoading.value = false;
        global.loader('hide');
    } else {
        orders.tryOrder(order_key.value).then(() => {
            isLoading.value = false;
            global.loader('hide');
        });
    }
});

const order = computed(function () {
    return orders.currentOrder;
});

let toastIs = true;
function runToast(type) {
    if(toastIs === true) {
        let text = (type == 'expired') ? 'Garantia do produto expirada' : 'Já existe uma solicitação para esse pedido';
        toastIs = false;
        global.tryWarningMessage(text);
        setTimeout(function() {
           toastIs = true;
        }, 4000);
    }

}
</script>