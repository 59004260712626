<template v-if="globalStore.domain_style">
    <svg
        id="svg_logo"
        :viewBox="globalStore.domain_style.title === 'Evemaster' ? '0 0 230 40' : '0 0 300 60'"
        xml:space="preserve"
    >
        <image :href="globalStore.domain_style.logotipo"></image>
    </svg>
</template>

<script setup>
import { useGlobalStore } from '@/store/global';
const globalStore = useGlobalStore();
</script>

<style scoped>

svg image{
    width: 100%;
    height: 100%;
}

</style>