import { defineStore } from "pinia";
import Api from "@/api";
import { useGlobalStore } from "@/store/global";

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useOrderStore = defineStore('order', {

    state: () => ({
        orders: {},
        currentOrder: {},
        refund: {},
        currentRefund: {}
    }),


    actions: {
        async tryOrders() {
            try {
                const response = await Api.get('api/orders');
                this.orders = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },
        
        async tryOrder(order_key) {
            this.currentOrder = {};
            this.refund = {};
            try {
                const response = await Api.get('api/orders/' + order_key);
                this.currentOrder = response.data.data;
                this.getCourses();
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryDownloadFile(url, title, page){
            try{
                const response = await Api.get(url, { responseType: 'blob', timeout: 0 });
                const blob = new Blob([response.data], { type: response.data.type });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = title;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                URL.revokeObjectURL(link.href);
                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryCancelRecurrence(page, order_key) {
            try {
                const response = await Api.post('api/orders/' + order_key + '/cancel-recurrence');
                const success = response.data.data.success;
                if(success) {
                    useGlobalStore().trySuccessMessage(success);
                }else {
                    useGlobalStore().tryErrorMessage(response.data.data.error);
                }
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryRefundCheck(order_key) {
            this.refund = {};
            try {
                const response = await Api.post('api/orders/' + order_key + '/refund');
                this.refund = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryRefundRequest(page, order_key, params) {
            const formData = new FormData();
            if(params['refund_images'] != null) {
                for (let i = 0; i < params['refund_images'].length; i++) {
                    formData.append('refund_images[]', params['refund_images'][i]);
                }
            }else {
                delete params['refund_images'];
            }
            for (let key in params) {
                if(params[key]) {
                    formData.append(key, params[key]);
                }
            }
            
            try {
                const response = await Api.post('api/orders/' + order_key + '/refund-request', formData);
                const success = response.data.data.success;
                if(success) {
                    this.tryOrder(order_key);
                    useGlobalStore().trySuccessMessage(success);
                    sessionStorage.setItem('refundCreatedFor', order_key);
                    this.router.push("/order/" + order_key);
                }
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryRefund(order_key) {
            try {
                const response = await Api.post('api/orders/' + order_key + '/refund/show');
                this.currentRefund = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryReplyRefund(page, order_key, params, type) {
            const formData = new FormData();
            if(params['refund_images'] != null) {
                for (let i = 0; i < params['refund_images'].length; i++) {
                    formData.append('refund_images[]', params['refund_images'][i]);
                }
            }else {
                delete params['refund_images'];
            }
            for (let key in params) {
                if(params[key]) {
                    formData.append(key, params[key]);
                }
            }

            try {
                const response = await Api.post('api/orders/' + order_key + '/refund/' + type, formData);
                const success = response.data.data.success;
                if(success) {
                    this.tryRefund(order_key);
                    useGlobalStore().trySuccessMessage(success);
                }
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async trySendCustomFields(page, order_key, params) {
            try {
                const response = await Api.post('api/orders/' + order_key + '/custom-fields', params);
                const success = response.data.data.success;
                if(success) {
                    this.tryOrder(order_key);
                    useGlobalStore().trySuccessMessage(success);
                }
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        getCourses() {
            let courses = {};
            if(this.currentOrder.status.slug == 'paid') {
                for(var i=0; i < this.currentOrder.products.length; i++) {
                    if(this.currentOrder.products[i].category == 'course') {
                        let currentCourses = {
                            ...courses,
                            [i]: {
                                ['title']: this.currentOrder.products[i].title,
                                ['id']: this.currentOrder.products[i].id
                            }
                        };
                        courses = currentCourses;
                    }
                }
            }
            this.currentOrder.courses = courses;
        }

    }

});