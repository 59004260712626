<template>
    <div>
        <span class="me-2">Pedido Nº {{ order.id }}</span> <BadgetOrder :order="order" />
    </div>
</template>

<script>
import BadgetOrder from "@/components/layout/order/BadgetOrder";

export default {
    props: {
        order: {
            required: true,
        }
    },
    components: {
        BadgetOrder
    }
};
</script>

<style scoped>
div {
    width: 100%;
    display: block !important;
    font-size: 16px;
    margin: 0;
    color: #646464;
    font-weight: 600;
    text-align: center;
    padding-top: 6px;
}

span {
     vertical-align: middle;
}

@media screen and (max-width: 370px) {
    div {
        padding-left: 40px;
        font-size: 14px !important;
    }
}

@media screen and (max-width: 330px) {
    span {
        display: block;
        margin: 0 !important;
    }

    div {
        padding: 0;
    }
}
</style>