//IMPORTS
import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue'
const app = createApp(App);


//ROUTER
import router from './router.js';


//IMPORT, CREATE AND USE PINIA
const pinia = createPinia();
pinia.use(({ store }) => {
    //ADD router TO PINIA
    store.router = markRaw(router) 
}); 
app.use(pinia);


//USER STORE
import { useAuthStore } from "@/store/auth";
const auth = useAuthStore();

router.beforeEach(function(to, _, next) {
    let main_title = document.title.split('-')[document.title.split('-').length - 1]
    document.title =  to.meta.title + main_title;
    document.body.style.overflow = (to.meta.overFlowHidden) ? 'hidden' : 'auto';
    if(to.meta.requiresAuth && !auth.tryCheckLogin()) {
        //
    }else{
        // eslint-disable-next-line
        grecaptcha.ready(() => {
            document.querySelectorAll(".grecaptcha-badge").forEach(el => el.style.visibility = "hidden");
        });
        next();
    }
});


router.afterEach((to) => {
    const recaptchaBadge = document.querySelectorAll(".grecaptcha-badge");
    if(to.meta.gTag) {
        // Google Analytics
        // eslint-disable-next-line
        gtag('config', window.GA_TRACKING_ID, {
            page_path: to.fullPath,
            app_name: to.meta.title + ' member',
            send_page_view: true,
        });
        // Google Analytics

        // eslint-disable-next-line
        grecaptcha.ready(() => {
            recaptchaBadge.forEach(el => el.style.visibility = "visible");
        });
    }else {
        // eslint-disable-next-line
        grecaptcha.ready(() => {
            recaptchaBadge.forEach(el => el.style.visibility = "hidden");
        });
        //Mostrar badge apenas nas páginas de login
    }
  });
app.use(router);
//ROUTER


//GLOBAL COMPONENTS
import TheLogo from '@/components/assets/TheLogo';
import BaseInput from '@/components/ui/BaseInput';
import BaseButton from '@/components/ui/BaseButton';
import BaseDialog from '@/components/ui/BaseDialog';


//USE GLOBAL COMPONENTS
app.component('TheLogo', TheLogo);
app.component('BaseInput', BaseInput);
app.component('BaseButton', BaseButton);
app.component('BaseDialog', BaseDialog);


//IMPORT USE TOAST
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
app.use(Toast);


//IMPORT USE MASKA
import Maska from 'maska';
app.use(Maska);

//BOOTSTRAP
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

//INIT
app.mount('#app')