import axios from 'axios';

const Api = axios.create({
    // eslint-disable-next-line
    'baseURL': process.env.VUE_APP_API_URL,
    withCredentials: true,
    headers: {"Content-Type": "multipart/form-data"},
    timeout: 10000,
    timeoutErrorMessage: 'Não foi possível realizar essa operação, tente novamente mais tarde'
});

    Api.interceptors.request.use(function (config) {
        const token = localStorage.getItem('auth_token');
        config.headers.Authorization =  token ? token : '';
        return config;
  });

export default Api;