<template>
    <div  class="box p-3 mb-3">
        <h4>Conteúdo</h4>

        <ul>
            <li v-for="course in courses" :key="course.id">
                <a :href="learn_url + 'course/' + order_key + '/' + course.id + [learn_token ? '?learn_token=' + learn_token : '']" class="mt-2 p-3 content_item">
                    <div class="d-flex align-items-center">
                        <svg class="me-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64H240l-10.7 32H160c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H346.7L336 416H512c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM512 64V288H64V64H512z"/></svg>
                        <div>
                            <h5>{{ course.title }}</h5>
                        </div>
                        <svg class="go_to" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                    </div>
                </a>
            </li>
        </ul>

    </div>
</template>
<script>
export default {
    data() {
        return {
            // eslint-disable-next-line
            learn_url: process.env.VUE_APP_LEARN_URL,
            learn_token: localStorage.getItem('learn_token'),
        }
    },
    props: {
        courses: {
            required: true
        },
        order_key: {
            required: true
        }
    }
};
</script>

<style scoped>
h4 {
    font-size: 15px !important;
}

ul {
    margin: 0 !important;
    padding: 0 !important;
}

li { 
    list-style: none;
}

.content_item {
    width: 100%;
    background: #f4f4f4;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid #d7d7d7;
    color: #595959 !important;
    text-decoration: none !important;
    cursor: pointer;
}

svg {
    width: 24px;
    fill: #595959;
}

h5 {
    font-size: 14px !important;
    margin-bottom: 0px !important;
}

.go_to {
    margin-left: auto;
    width: 20px;
}
</style>