<template>
    <div v-if="!already_sended" class="box p-3 mb-3">
        <h6 class="mb-3">Complete seu pedido</h6>
        <form @submit.prevent="fieldsSubmit">
            <div class="row">
                <div v-for="(field, key) in fields" :key="key"  class="col-12">
                    <BaseInput
                        :label="field.title"
                        type="text"
                        :id="'customfield_' + field.id"
                        :page="page"
                        v-model="customfields['customfield_' + field.id]"
                        :mask="field.mask"
                    />
                </div>
                <div class="col-12">
                    <BaseButton 
                        class="btn-success d-block w-100 mt-3 mb-2"
                        :loading="formLoading"
                        :disabled="formLoading"
                    >Completar</BaseButton>
                </div>
            </div>
        </form>
    </div>

    <div v-else class="box p-3 mb-3">
        <h6 class="mb-3">Dados adicionais</h6>
        <div class="row">
            <div class="col-12">
                <p v-for="(field, key) in fields" :key="key" class="mb-1">
                    <strong class="">{{ field.title }}: </strong>
                    {{ field.value }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const props = defineProps(['page', 'fields', 'order_key'])
const page = ref(props.page);
const fields = ref(props.fields);
const order_key = ref(props.order_key);

const already_sended = ref(false);

const customfields = ref({});
for (let key in fields.value) {
    customfields.value['customfield_' + fields.value[key].id] = fields.value[key].value;
    if(fields.value[key].value) {
        already_sended.value = true;
    }
}

import { useOrderStore } from "@/store/order";
const orders = useOrderStore();

import { useGlobalStore } from "@/store/global";
const global = useGlobalStore();

const formLoading = ref(false);

onMounted(() => {
    delete global.errors[page.value];
});

async function fieldsSubmit() {

    if(formLoading.value === true) {
        return;
    }

    let errors = false;

    for (let key in customfields.value) {

        if(!customfields.value[key]) {
            global.tryLocalErrors(key, 'Campo obrigatório', page.value);
            errors = true;
        }
    }

    if(!errors) {
        formLoading.value = true;
        const params = customfields.value;
        orders.trySendCustomFields(page.value, order_key.value, params).then(() =>{
            formLoading.value = false;
        });
    }
}
</script>