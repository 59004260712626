<template>
    <div class="form-group">
        <label :for="id" class="checkbox" :class="[error ? 'danger-label' : '']">{{ label }}
            <input
                :id="id"
                type="checkbox"
                :checked="checked"
                @input="updateInput"
            />
            <span class="checkmark" :class="[error ? 'danger-border' : '']"></span>
        </label>

        <span v-if="!!error" class="invalid-feedback d-block" role="alert">{{ error }}</span>
    </div>
</template>

<script>
import { useGlobalStore } from "@/store/global";

export default {
    emits: ["update:checked"],
    data() {
        return {
            global: useGlobalStore()
        }
    },
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },

        id: {
            type: String,
            required: true
        },

        page: {
            type: String,
            required: true
        },

        checked: {}
    },
    methods: {
        updateInput(event) {
            this.$emit('update:checked', event.target.checked);
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                delete this.global.errors[this.$props.page][this.$props.id];
            }
        },
    },
    computed: {
        error() {
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                return this.global.errors[this.$props.page][this.$props.id];
            }
            return null;
        }
    }
};
</script>

<style scoped>
.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-block: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    color: #545454;
}

/* Hide the browser's default checkbox */
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: #f4f4f4;
    border: 1px solid #e2e2e2;
    border-radius: 15px;
    -webkit-transition: all 100ms linear;
    -ms-transition: all 100ms linear;
    transition: all 100ms linear;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
    background-color: #e2e2e2;
    border-color: #e2e2e2;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
    background-color: #28a745;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
    left: 5px;
    top: 4px;
    width: 13px;
    height: 13px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill=%27%23fff%27 d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/%3E%3C/svg%3E"); /*Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.*/
    background-repeat: no-repeat;
}

.danger-label {
    color: #d60e0e;
}

.danger-border {
    border-color: #fd6d6d;
    background-color: #ffd2d2;
}
</style>