<template>
    <footer>
        <p class="text-center m-0 py-3">© {{ currentYear }} Fullsale. Todos os direitos reservados.</p>
    </footer>
</template>

<script>
export default {
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    }
}
</script>

<style scoped>
footer {
    color: #8a8a8a;
    margin-top: -53px !important;
}
</style>