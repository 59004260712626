<template>
    <div class="mb-2 form-group">
        <label v-if="label" :class="[error ? 'danger-label' : '']">{{ label }}</label>
        <select 
            :class="'form-select select-box ' + [error ? 'danger-border' : '']"
            :value="modelValue"
            @input="updateInput"
            :required="!!required"
        >
            <option selected disabled="disabled" value="">{{ placeholder }}</option>
            <option v-for="(item, key) in items" :key="key" :value="key">{{ item }}</option>
        </select>
        <span v-if="!!error" class="invalid-feedback" :class="[error ? 'd-block' : '']" role="alert">{{ error }}</span>
    </div>
</template>

<script>
import { useGlobalStore } from "@/store/global";

export default {
    emits: ["update:modelValue"],
    data() {
        return {
            global: useGlobalStore()
        }
    },
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },

        modelValue: {
            type: String,
        },

        placeholder: {
            type: String,
            required: false,
            default: 'Selecione'
        },

        items: {
            required: true,
        },

        page: {
            type: String,
            required: false
        },

        required: {
            type: Boolean,
            required: false,
            default: null,
        },

        id: {
            type: String,
            required: true
        },
        
    },
    methods: {
        updateInput(event) {
            this.$emit("update:modelValue", event.target.value);
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                delete this.global.errors[this.$props.page][this.$props.id];
            }
        }
    },
    computed: {
        error() {
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                return this.global.errors[this.$props.page][this.$props.id];
            }
            return null;
        }
    }
};
</script>

<style scoped>
label {
    min-height: 21px;
    display: block;
    margin-block: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    color: #545454;
}

.select-box {
    border: 1px solid #e2e2e2;
    width: 100%;
    padding: 8px 15px;
    border-radius: 20px;
    color: #444;
    font-size: 14px;
    font-weight: 300;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.danger-border {
    border-color: #fd6d6d;
    color: #d60e0e;
    background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23fd6d6d%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
}

.danger-label {
    color: #d60e0e;
}
</style>