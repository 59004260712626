<template>
    <router-link v-if="props.backTo"  :to="backTo" class="back_arrow btn btn-secondary">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            />
        </svg>
        <span> Voltar </span>
    </router-link>
    <a v-else @click.prevent="closeDialog" class="back_arrow btn btn-secondary">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            />
        </svg>
        <span> Voltar </span>
    </a>
</template>

<script setup>

const props = defineProps({
    backTo: {
        required: false,
        default: null
    }
});

const emit = defineEmits(["closePopup"]);

function closeDialog() {
    emit("closePopup");
}

</script>

<style scoped>
.back_arrow {
    display: inline-block;
    text-decoration: none !important;
    color: #545454 !important;
    padding: 7px 15px 7px 15px;
    font-weight: 600;
    border-radius: 20px;
    margin-left: -5px;
    background: #fff;
    border: 0;
}

.back_arrow:hover {
    background: #eae9e9;
}

.back_arrow svg {
    width: 16px;
    height: 18px;
    font-weight: 900;
    fill: #545454;
    margin-right: 6px;
    margin-bottom: 3px;
}
@media (max-width: 768px) {
    span {
        display: none;
    }

    svg {
        margin: 0 !important;
    }

    .back_arrow {
        padding: 4px 15px 7px 15px;
    }
}
</style>