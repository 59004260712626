<template>
    <div class="box p-3  mb-3">
        <div class="row">
            <div class="col">
                <strong class="d-block">Solicitação de reembolso</strong>
                <p class="col mt-3 message" :style="{  color: refund.status_color }">
                    <svg xmlns="http://www.w3.org/2000/svg" :style="{  fill: refund.status_color }" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z"/></svg>
                    {{ refund.status }}
                </p>
                <BaseButton link :to="refundLink" class="btn-primary d-block w-100 mt-3 mb-2 position-relative">
                    Visualizar
                    <p v-if="refund.notification" class="responded_alert" data-toggle="tooltip" data-placement="top" title="Atualização do reembolso">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 31.1c-141.4 0-255.1 93.12-255.1 208c0 49.62 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734c1.249 3 4.021 4.766 7.271 4.766c66.25 0 115.1-31.76 140.6-51.39c32.63 12.25 69.02 19.39 107.4 19.39c141.4 0 255.1-93.13 255.1-207.1S397.4 31.1 256 31.1zM127.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S145.7 271.1 127.1 271.1zM256 271.1c-17.75 0-31.1-14.25-31.1-31.1s14.25-32 31.1-32s31.1 14.25 31.1 32S273.8 271.1 256 271.1zM383.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S401.7 271.1 383.1 271.1z"/></svg>
                    </p>
                </BaseButton>
            </div>
        </div>
    </div>
</template>


<script>
import { useRoute } from "vue-router";

export default {
    data() {
        return {
            route: useRoute()
        }
    },
    props: {
        refund: {
            required: true,
        }
    },
    computed: {
        refundLink() {
            return this.route.params.code + '/refund';
        }
    }
}
</script>

<style scoped>
    .message svg {
        width: 14px;
    }
    .responded_alert {
        margin: 0;
        position: absolute;
        right: 30px;
        bottom: 30px;
    }
    .responded_alert svg {
        width: 25px;
        fill: red;
    }
</style>