import { defineStore } from "pinia";
import Api from "@/api";

import { useGlobalStore } from "@/store/global";
import { useOrderStore } from "@/store/order";
import { useUserStore } from "@/store/user";

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA


export const useAuthStore = defineStore('auth', {

    state: () => ({
        name: '',
        email: ''
    }),


    actions: {
        tryLogout(isError) {
            if(sessionStorage.getItem('redirectAfterLogin') === null) {
                sessionStorage.setItem('redirectAfterLogin', window.location.pathname);
            }
            this.$reset();
            useOrderStore().$reset();
            useUserStore().$reset();
            if(!isError){
                if(localStorage.getItem('auth_token') !== null){
                    Api.get('api/logout')
                } else{
                    Api.get('api/logout');
                }
            }
            useGlobalStore().tryDomain();
            this.router.push('/login');
        },

        async tryCheckLogin() {
            try {
                const response = await Api.get('api/user');
                this.email = response.data.email;
                this.name = response.data.name;
                return true;
            } catch (_) {
                this.tryLogout(true);
                return false;
            }
        },

        async tryLogin(page, params) {
            
            try {
                const response = await Api.post("api/login", {
                    email: params.email,
                    password: params.password,
                    recaptcha_token: params.recaptcha_token
                });

                this.email = response.data.email;
                this.name = response.data.name;
                localStorage.setItem('auth_token', 'Bearer ' + response.data.token);
                localStorage.setItem('learn_token', response.data.learn_token)
                const redirect = sessionStorage.getItem('redirectAfterLogin');
                if(redirect) {
                    this.router.push(redirect);
                    sessionStorage.removeItem('redirectAfterLogin');
                }else {
                    this.router.push('/');
                }

                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return true;
            }
        },

        async tryRequestResetPassword(page, params) {
            try {
                const response = await Api.post("api/login/request-reset-password", {
                    email: params.email,
                    recaptcha_token: params.recaptcha_token
                });

                const success = response.data.data.success;
                if(success) {
                    useGlobalStore().trySuccessMessage(success);
                }
                this.router.push('/login');
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return true;
            }
        },

        async tryCheckResetPasswordCode(code) {
            try {
                await Api.post("api/login/check-reset-password-code", {
                    code: code
                });
            } catch (_) {
                useGlobalStore().tryErrorMessage('Código inválido ou expirado, tente novamente');
                this.router.push('/login');
            }
        },

        async tryResetPassword(page, params, token) {
            try {
                const response = await Api.post("api/login/reset-password", {
                    email: params.email,
                    password: params.password,
                    password_confirmation: params.password_confirmation,
                    token: token,
                    recaptcha_token: params.recaptcha_token
                });
                const success = response.data.data.success;
                if(success) {
                    useGlobalStore().trySuccessMessage(success);
                }
                this.router.push('/login');
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryRequestLoginWithoutPassword(page, params) {
            try {
                await Api.post("api/login/without-password", {
                    email: params.email,
                    recaptcha_token: params.recaptcha_token
                });
                this.email = params.email;
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        async tryLoginWithoutPassword(page, params) {
            try {
                const response = await Api.post("api/login/without-password-code", {
                    code: params.code,
                    recaptcha_token: params.recaptcha_token,
                    email: this.email,
                });

                this.email = response.data.email;
                this.name = response.data.name;
                localStorage.setItem('auth_token', 'Bearer ' + response.data.token);
                localStorage.setItem('learn_token', response.data.learn_token)
                this.router.push('/');
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return true;
            }
        },
    }

});
