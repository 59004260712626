<template>
    <TheHeader />
    <div class="w-100">
        <div class="container-fluid">
            <div v-if="!isLoading && order.order_key && refund.order_key" class="container mt-3">
                <div class="pb-4">
                    <div class="align space-between">
                        <RefundTitle>
                            Solicitação de reembolso #{{ order.id }} 
                        </RefundTitle>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-xl-8 mb-4 mb-xl-0 status_main">
                        <div class="box">
                            <div class="p-4">
                                <div class="mb-3">
                                    <h2 >Status</h2>
                                    <span class="badget" :style="{  backgroundColor: refund.status.color }">{{ refund.status.name }}</span>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <p class="mb-1"><strong class="d-block mb-1">Motivo da solicitação </strong> {{ refund.reason }}</p>
                                    </div>
                                    <div class="col-md">
                                        <p class="mb-1"><strong class="d-block mb-1">Data da solicitação </strong> {{ dataLong(refund.created_at) }}</p>
                                    </div>
                                    <div v-if="refund.replay_deadline" class="col-md">
                                        <p class="mb-1">
                                            <strong class="d-block mb-1">Data limite de resposta </strong> {{ dataShort(refund.replay_deadline) }}
                                            <svg style="user-select:none" @click="runToast('O Procon condece até 10(dez) dias à empresas, para responderem reclamações feitas pelos consumidores.')" class="info-circle domain_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 400c-18 0-32-14-32-32s13.1-32 32-32c17.1 0 32 14 32 32S273.1 400 256 400zM325.1 258L280 286V288c0 13-11 24-24 24S232 301 232 288V272c0-8 4-16 12-21l57-34C308 213 312 206 312 198C312 186 301.1 176 289.1 176h-51.1C225.1 176 216 186 216 198c0 13-11 24-24 24s-24-11-24-24C168 159 199 128 237.1 128h51.1C329 128 360 159 360 198C360 222 347 245 325.1 258z"/></svg>
                                        </p>
                                    </div>

                                </div>
                            </div>

                            <div class="p-4 divisor_top">
                                <p class="mb-1"><strong class="d-block mb-1">Detalhes da solicitação </strong> <span v-html="refund.text"></span></p>
                            </div>


                            <div v-if="refund.images" class="divisor_top">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Imagens</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(image, key) in refund.images" :key="key">
                                                <td><a :href="image.url" target="_blank">{{ image.name }}</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-for="(reply, key) in refund.replies" :key="key" class="box mt-4">
                            <div class="p-4">
                                <div class="row">
                                    <div class="col-md">
                                        <p class="mb-1"><strong class="d-block mb-1">Respondido por </strong> 
                                            {{ reply.replied_by }}
                                        </p>
                                    </div>
                                    <div class="col-md">
                                        <p class="mb-1"><strong class="d-block mb-1">Data </strong> {{ dataLong(reply.created_at) }}</p>
                                    </div>
                                    
                                    <div class="col-md">
                                        <p class="mb-1"><strong class="d-block mb-1">Status </strong> <span class="badget" :style="{  backgroundColor: reply.status.color }">{{ reply.status.name }}</span></p>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4 divisor_top">
                                <p class="mb-1"><strong class="d-block mb-1">Detalhes </strong> <span v-html="reply.text"></span></p>
                            </div>


                            <div v-if="reply.status.slug == 'waiting_shipping'" class="p-4 divisor_top">
                                <p class="mb-1"><strong class="d-block mb-1">Código para envio do produto </strong> {{ refund.shipping_reverse_code }}</p>
                            </div>



                            <div v-if="reply.images" class="divisor_top">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Imagens</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(image, key) in reply.images" :key="key">
                                                <td><a :href="image.url" target="_blank">{{ image.name }}</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                        <div v-if="refund.is_replayable || refund.is_refundable" class="text-end refund_response">
                            <div class="p-4"> 
                                <BaseButton 
                                    v-if="refund.is_refundable" 
                                    class="btn btn-primary d-inline-block mx-2"
                                    @click="refundResponseIs('desist')"
                                >Desistir</BaseButton>

                                <BaseButton 
                                    v-if="refund.status.slug == 'waiting_shipping'" 
                                    class="btn btn-success d-inline-block mx-2"
                                    @click="refundResponseIs('shipping_sended')"
                                >Informar envio do produto</BaseButton>

                                <BaseButton
                                    v-if="refund.is_replayable"
                                    class="btn btn-primary d-inline-block mx-2"
                                    @click="refundResponseIs('reply')"
                                >Responder</BaseButton>
                            </div>
                        </div>

                    </div>

                    <div class="col-12 col-xl-4">
                        <div class="box">

                            <div class="p-4 divisor_bottom">
                                <div class="row">
                                    <div class="col-md">
                                        <p class="mb-1"><strong class="d-block mb-1">Loja</strong> <span class="badget" :style="{  backgroundColor: order.store.color }">{{ order.store.name }}</span></p>
                                    </div>
                                    <div class="col-md">
                                        <p class="mb-1"><strong class="d-block mb-1">Data da compra</strong>{{ dataShort(order.info.date) }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4 divisor_bottom">
                                <p class="mb-1"><strong class="d-block mb-1">Método de pagamento</strong>
                                {{ order.info.payment }} <span v-if="order.info.installments">{{ order.info.installments }}</span></p>
                            </div>
                            <TableOrder
                                v-if="order.products"
                                :products="order.products" 
                                :amount="order.amount" 
                                :shipping="order.shipping"
                                :discount="order.discount"
                                class="remove_mb_and_border"
                            ></TableOrder>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <BaseDialog :samePage="true" v-if="popup" @close-popup="closePopup">
        <RefundCancelHeader :title="popupInfo.title" :order="order.id" @closePopup="closePopup">
        </RefundCancelHeader>

        <form class="p-2" @submit.prevent="refundReply" >

            <BaseInput
                :label="popupInfo.subtitle"
                type="text"
                id="text"
                textarea
                v-model="inputs.text"
                :page="page"
                class="mb-3"
            />

            <BaseFileUpload
                label="Imagens"
                id="refund_images"
                v-model="inputs.refund_images"
                :page="page"
                multiple
                accept="image/png, image/gif, image/jpeg, image/jpg"
                info="Imagens de prova ou contestação, no máximo 3 arquivos."
            ></BaseFileUpload>   

            <BaseCheckBox
                v-if="responseType == 'desist'"
                label="Declaro que desejo realizar a desistência do pedido de reembolso de forma definitiva."
                class="mt-4 mb-0"
                id="declaration"
                v-model:checked="inputs.declaration"
                :page="page"
            ></BaseCheckBox>


            <div class="row">
                <div class="col-12">
                    <BaseButton 
                        class="btn-primary d-block w-100 mt-3 mb-2"
                        :loading="formLoading"
                        :disabled="formLoading"
                    >{{ popupInfo.button }}</BaseButton>
                </div>
            </div>
        </form>
    </BaseDialog>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import TheHeader from "@/components/layout/includes/TheHeader";
import TableOrder from '@/components/layout/order/TableOrder';
import BaseCheckBox from '@/components/ui/BaseCheckBox';
import BaseFileUpload from '@/components/ui/BaseFileUpload';

import RefundTitle from '@/components/layout/order/refund/RefundTitle.vue'
import RefundCancelHeader from '@/components/layout/order/refund/RefundCancelHeader.vue'

import { useRoute } from "vue-router";

const route = useRoute();
const order_key = computed(function () {
    return route.params.code;
});

const page = ref('currentRefund');

import { useOrderStore } from "@/store/order";
const orders = useOrderStore();

const orderIsLoading = ref(true);
const refundIsLoading = ref(true);

const isLoading = computed(function () {
    return (orderIsLoading.value == false && refundIsLoading.value == false) ? false : true;
});

import { useGlobalStore } from "@/store/global";
const global = useGlobalStore();

onMounted(() => {
    document.body.style.overflow = 'auto';
    delete global.errors[page.value];

    global.loader('show');
    if (orders.currentOrder && orders.currentOrder.order_key != order_key.value || !orders.currentOrder) {
        orders.tryOrder(order_key.value).then(() => {
            orderIsLoading.value = false;
            global.loader('hide');
        });
    }else {
        orderIsLoading.value = false;
        global.loader('hide');
    }

    if (orders.currentRefund && orders.currentRefund.order_key != order_key.value || !orders.currentRefund) {
        orders.tryRefund(order_key.value).then(() => {
            refundIsLoading.value = false;
            global.loader('hide');
        });
    }else {
        refundIsLoading.value = false;
        global.loader('hide');
    }
});

const order = computed(function () {
    return orders.currentOrder;
});

const refund = computed(function () {
    return orders.currentRefund;
});

let toastIs = true;
function runToast(text) {
    if(toastIs === true) {
        toastIs = false;
        global.tryInfoMessage(text);
        setTimeout(function() {
           toastIs = true;
        }, 6000);
    }
}

const popup = ref(false);

function closePopup() {
    popup.value = !popup.value;
}

const responseType = ref('');
function refundResponseIs(type) {
    popup.value = true;
    responseType.value = type;
}

const popupInfo = computed(function () {
    let title = '';
    let subtitle = '';
    let button = '';
    if(responseType.value == 'desist') {
        title = 'Desistência do reembolso ';
        subtitle = 'Deixe abaixo informações sobre a desistência do reembolso.';
        button = 'Desistir';

    }else if(responseType.value == 'shipping_sended') {
        title = 'Informar envio do produto';
        subtitle = 'Deixe informações sobre o envio do produto para o vendedor e se possível anexe uma cópia do comprovante de envio abaixo.';
        button = 'Responder';

    }else if(responseType.value == 'reply') {
        title = 'Responder';
        subtitle = 'Envie uma resposta para o vendedor ou deixe mais informações sobre a solicitação.';
        button = 'Responder';
    }
    return {
        'title': title,
        'subtitle': subtitle,
        'button': button
    }
});

const inputs = ref({
    text: '',
    refund_images: null,
    declaration: false
});

const formLoading = ref(false);

async function refundReply() {

    if(formLoading.value === true) {
        return;
    }

    let errors = false;

    if (!inputs.value.text || inputs.value.text.length < 10) {
        global.tryLocalErrors('text', 'Detalhes necessários, mínimo 10 caracteres', page.value);
        errors = true;
    }

    if(inputs.value.refund_images && inputs.value.refund_images.length > 3) {
        global.tryLocalErrors('refund_images', 'Selecione até 3 imagens, '+ inputs.value.refund_images.length +' selecionadas', page.value);
        errors = true;
    }

    if(!inputs.value.declaration && responseType.value == 'desist') {
        global.tryLocalErrors('declaration', 'O campo acima é obrigatório', page.value);
        errors = true;
     }

    if(!errors) {
        formLoading.value = true;
        const params = inputs.value;
        orders.tryReplyRefund(page.value, order_key.value, params, responseType.value).then((response) =>{
            if(response) {
                popup.value = false;
                document.body.style.overflow = 'auto';
                inputs.value.text = '';
            }
            formLoading.value = false;
        });
    }
}

function dataLong(d) {
    return global.getFormatDateLong(d);
}

function dataShort(d) {
    return global.getFormatDate(d);
}

</script>

<style scoped>
.refund-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.loading-background {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
}

.status_main h2 {
    font-size: 16px;
    color: #414951;
    font-weight: 600;
}

.remove_mb_and_border {
    border: 0;
    margin-bottom: 0 !important;
}

.divisor_bottom {
    border-bottom: 1px #d7d7d7 solid !important;
}

.divisor_top {
    border-top: 1px #d7d7d7 solid !important;
}
.status_main .box {
    font-weight: 400;
    border-radius: 20px 20px 20px 20px;
}

.status_main .box {
    font-weight: 400;
    border-radius: 20px 20px 20px 20px;
}

.status_main .box:nth-last-child(2){
    border-radius: 20px 20px 0px 0px;
}

.box {
    font-weight: 400;
    border-radius: 20px;
}

.info-circle {
    fill: #595959;
    width: 14px;
    cursor: pointer;
    margin-top: -3px;
}

.refund_response {
    border: 1px solid #d7d7d7;
    margin-top: -5px;
    background: #fff;
    border-radius: 0px 0px 20px 20px;
}

.popup-title  h2{
    display: inline-block;
    font-size: 16px;
    margin: 0;
    color: #414951;
    font-weight: 600;
}

.popup-title .domain_svg {
    width: 18px;
    vertical-align: text-top;
    margin-right: 10px;
}

.popup-title p {
    color: #545454;
    font-weight: 600;
}
</style>