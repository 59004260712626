import { createRouter, createWebHistory } from "vue-router";

//LOGIN PAGES
import TheLogin from '@/pages/login/TheLogin';
import TheForgotPassword from '@/pages/login/TheForgotPassword';
import TheLoginWithOutPassword from '@/pages/login/TheLoginWithOutPassword';


//PAGES
import TheHome from '@/pages/home/TheHome';
import TheNotFound from '@/pages/errors/TheNotFound';
import TheOrder from '@/pages/orders/TheOrder';
import TheFaq from '@/pages/orders/TheFaq';
import TheRefundRequest from '@/pages/orders/TheRefundRequest';
import TheAccount from '@/pages/account/TheAccount';
import TheRefund from '@/pages/orders/TheRefund';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: TheHome,
            children: [
                {
                    path: '/order/:code',
                    component: TheOrder,
                    meta: {
                        overFlowHidden: true
                    }
                },
                {
                    path: '/order/:code/faq',
                    component: TheFaq,
                    meta: {
                        overFlowHidden: true
                    }
                },
                {
                    path: '/order/:code/refund-request',
                    component: TheRefundRequest,
                    meta: {
                        overFlowHidden: true
                    }
                }
            ],
            meta: {
                title: 'Pedidos - ',
                requiresAuth: true,
                gTag: false
            }
        },
        {
            path: '/order/:code/refund',
            component: TheRefund,
            meta: {
                title: 'Solicitação de reembolso - ',
                requiresAuth: true,
                gTag: false
            }
        },
        {
            path: '/order',
            redirect: '/',
            meta: {
                gTag: false
            }
        },
        {
            path: '/account',
            component: TheAccount,
            meta: {
                title: 'Meus Dados - ',
                requiresAuth: true,
                gTag: false
            }
        },
        {
            path: '/login',
            component: TheLogin,
            meta: {
                title: 'Login - ',
                requiresAuth: false,
                gTag: true
            }
        },
        {
            path: '/forgot-password/:code?',
            component: TheForgotPassword,
            props: true,
            meta: {
                title: 'Recuperar senha - ',
                requiresAuth: false,
                gTag: true
            }
        },
        {
            path: '/login-without-password',
            component: TheLoginWithOutPassword,
            meta: {
                title: 'Login sem senha - ',
                requiresAuth: false,
                gTag: true
            }
        },
        {
            path: '/:notFound(.*)',
            component: TheNotFound,
            meta: {
                title: 'Página não encontrada - ',
                requiresAuth: false,
                gTag: false
            }
        }
    ]
});
export default router;