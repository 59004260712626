<template>
    <div class="popup-title d-flex">
        <BackButtonOrder  @closePopup="closePopup"></BackButtonOrder>
        <h2> {{ props.title }}  #{{ order }}</h2>
    </div>
</template>
<script setup>
import BackButtonOrder from "@/components/layout/order/header/BackButtonOrder";

const emit = defineEmits(["closePopup"]);

const props = defineProps([
    'title' , 'order'
]);

function closePopup() {
    emit("closePopup");
}

</script>
<style scoped>

.popup-title{
    margin-bottom: 15px;
}

.popup-title h2 {
    font-size: 16px;
    color: #646464;
    font-weight: 600;
    padding-top: 6px;
}

p{
    font-weight: 500;
    color: #646464;
}

.popup-title a{
    margin-right: 10px;
    height: 38px;
}

.popup-title a :deep(span){
    display: none;
}

</style>