<template>
    <header class="py-4">
        <div class="container-fluid">
            <div class="container d-flex align-items-center justify-content-center"> 
                <router-link to="/">
                    <TheLogo id="logo"/>
                </router-link>
                <div class="menu_drop_notification position-relative">
                    <button id="dropdownMenu" aria-haspopup="true" aria-expanded="false">
                        <svg id="notification_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M207.1 16C207.1 7.164 215.2 0 223.1 0C232.8 0 240 7.164 240 16V32.79C320.9 40.82 384 109 384 192V221.1C384 264.8 401.4 306.7 432.3 337.7L435 340.4C443.3 348.7 448 359.1 448 371.7C448 396.2 428.2 416 403.7 416H44.28C19.83 416 0 396.2 0 371.7C0 359.1 4.666 348.7 12.97 340.4L15.72 337.7C46.63 306.7 64 264.8 64 221.1V192C64 109 127.1 40.82 208 32.79L207.1 16zM223.1 64C153.3 64 95.1 121.3 95.1 192V221.1C95.1 273.3 75.26 323.4 38.35 360.3L35.6 363C33.29 365.3 31.1 368.5 31.1 371.7C31.1 378.5 37.5 384 44.28 384H403.7C410.5 384 416 378.5 416 371.7C416 368.5 414.7 365.3 412.4 363L409.7 360.3C372.7 323.4 352 273.3 352 221.1V192C352 121.3 294.7 64 223.1 64H223.1zM223.1 480C237.9 480 249.8 471.1 254.2 458.7C257.1 450.3 266.3 445.1 274.6 448.9C282.9 451.9 287.3 461 284.4 469.3C275.6 494.2 251.9 512 223.1 512C196.1 512 172.4 494.2 163.6 469.3C160.7 461 165.1 451.9 173.4 448.9C181.7 445.1 190.9 450.3 193.8 458.7C198.2 471.1 210.1 480 223.1 480z"/></svg>
                    </button>
                    <div class="dropdown-menu dropdown_notification_menu" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                        <p class="px-3">
                            <strong>
                                Notificações
                            </strong>
                        </p> 
                        <div class="px-3 mb-4">
                            <router-link to="/">
                            </router-link>
                        </div>
                        <div class="d-flex text-center justify-content-center flex-column mb-2">
                            <router-link id="btn_all_notif"  to="/">
                                Ver todas >
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="menu_drop_account position-relative">
                    <button id="dropdownMenu" aria-haspopup="true" aria-expanded="false">
                        <div id="account_icon" class="d-flex text-center justify-content-center flex-column open-close-dropdown">
                            {{ firstLetterName }}
                        </div>
                    </button>
                    <div :class="'dropdown-menu ' + [showDropDown == true ? 'show' : '']" x-placement="bottom-start" aria-labelledby="dropdownMenu">
                        <div class="ps-2">
                            <div id="account_icon" class="d-flex text-center justify-content-center flex-column">
                                {{ firstLetterName }}
                            </div>
                            <div class="account_mini_info">
                                <p>
                                    <strong class="text-dark">
                                        {{ userFistName }}
                                    </strong>
                                    <span class="d-block email_user_overflow">
                                        {{ userEmail }}
                                    </span>                                 
                                </p>
                            </div>
                        </div>
                        <router-link @click="closeDropDown" to="/" class="dropdown-item d-flex justify-content-center flex-column">Meus pedidos</router-link>
                        <router-link @click="closeDropDown" to="/account" class="dropdown-item d-flex justify-content-center flex-column">Minha conta</router-link>
                        <router-link to="/" @click="logout" class="dropdown-item d-flex justify-content-center flex-column">
                            <div>
                                <svg id="logout_arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M176 448h-96C53.53 448 32 426.5 32 400v-288C32 85.53 53.53 64 80 64h96C184.8 64 192 56.84 192 48S184.8 32 176 32h-96C35.88 32 0 67.88 0 112v288C0 444.1 35.88 480 80 480h96C184.8 480 192 472.8 192 464S184.8 448 176 448zM502.6 233.4l-128-128c-9.156-9.156-22.91-11.91-34.88-6.938C327.8 103.4 320 115.1 320 128l.0918 63.1L176 192C149.5 192 128 213.5 128 240v32C128 298.5 149.5 320 176 320l144.1-.001L320 384c0 12.94 7.797 24.62 19.75 29.56c11.97 4.969 25.72 2.219 34.88-6.938l128-128C508.9 272.4 512 264.2 512 256S508.9 239.6 502.6 233.4zM352 384V288H176C167.2 288 160 280.8 160 272v-32C160 231.2 167.2 224 176 224H352l-.0039-96l128 128L352 384z"/></svg>
                                Sair
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import { computed, ref, watch } from "vue";

import { useAuthStore } from "@/store/auth";
const auth = useAuthStore();

const userFistName = computed(function () {
    const userNameArray = auth.name.split(" ");
    return userNameArray[0];
});

const userEmail = computed(function(){
    return auth.email;
})

import { useGlobalStore } from '@/store/global';
const global = useGlobalStore();

const firstLetterName = computed(function(){
    return global.getFirstLetterName(auth.name);
});

function logout() {
    auth.tryLogout();
}

const showDropDown = ref(false);
window.addEventListener("click", function(e) {
    const parent = e.target.closest('.dropdown-menu');
    if(parent == null && showDropDown.value == true || showDropDown.value == true && e.target.classList.contains('open-close-dropdown')) {
        showDropDown.value = false;
    }else  if(e.target.classList.contains('open-close-dropdown') && showDropDown.value != true) {
        showDropDown.value = true;
    }
});

const closeDropDown = (() => {
    showDropDown.value = false;
});

const darkMode = ref((sessionStorage.getItem('darkMode') == 1) ? 1 : 0);

watch(darkMode, function (curValue) {
    if(curValue) {
        document.body.classList.add('dark');
        sessionStorage.setItem('darkMode', 1);
    }else {
        document.body.classList.remove('dark');
        sessionStorage.setItem('darkMode', 0);
    }
});
</script>

<style scoped>

button {
    border: 0;
}

#logo {
    width: 140px;
}

#notification_icon{
    width: 27px;
    height: 30px;
    fill: #D9D9D9;
}

#btn_all_notif {
    font-size: 12px;
    color: #C0C0C0 !important;
}

.menu_drop_notification {
    margin-left: auto;
    margin-right: 15px;
    display: none;
}

.menu_drop_notification button{
    background-color: transparent;
}

.menu_drop_account, .menu_drop_notification {
    height: 30px;
}

.dropdown_notification_menu{
    height: auto !important;;
    width:330px !important;
}

.notification_desc{
    font-size: 13px !important;
    font-weight: 400;
    color: #212529 !important;
    height: 20px;
    margin-bottom: 5px;
}

.notification_time_info{
    font-weight: 400;
    font-size: 12px;
    color: #C0C0C0;
}

.menu_drop_account {
    margin-left: auto;
}

.menu_drop_account button {
    padding: 0px;
    background: transparent !important;
    transition: all 0.35s ease;
    border-radius: 60px;
}

.menu_drop_account button:hover, .menu_drop_account button:focus {
    -webkit-box-shadow: 0px 0px 0px 5px rgba(239,239,239,1);
    -moz-box-shadow: 0px 0px 0px 5px rgba(239,239,239,1);
    box-shadow: 0px 0px 0px 5px rgba(239,239,239,1);
}

.dropdown-notifications {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    position:absolute;
    will-change: transform;
    top: 0;
    left: 0;
    transform: translate3d(0px, 65px, 0px) !important;
    width: 230px;
    padding: 20px 15px 12px 15px;
    border-radius: 15px;
    inset: 0px 0px auto auto !important;
}

.dropdown-menu {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    position:absolute;
    will-change: transform;
    top: 0;
    left: 0;
    transform: translate3d(0px, 50px, 0px) !important;
    width: 260px;
    padding: 15px 12px 12px 12px !important;
    border-radius: 20px;
    inset: 0px 0px auto auto !important;
}

.dropdown-menu #account_icon {
    margin-top: 2px;
}

.dropdown-item {
    font-size: 14px;
    cursor: pointer;
    color: #212529 !important;
    border:0;
    padding: 6px 8px;
    height: 40px;
    border-radius: 6px;
    border: none;
    width: 100%;
}

.dropdown-item:hover {
    background-color: #efefef !important;
}

.logout_dropdown_item{
    height: 20px;
    padding: 0px 4px;
    color: black;
}

#account_icon {
    width: 30px;
    height: 30px;
    background-color: #646464;
    border-radius: 80%;
    text-align: center;
    justify-content: center;
    color: white;
    float: left;
}


.account_mini_info {
    padding-left: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: #212529;
}

.account_mini_info span {
    font-size: 13px;
    color: #C0C0C0;
    font-weight: 300;
}

.email_user_overflow{
    white-space: nowrap;   
    overflow: hidden;
    text-overflow: ellipsis;
}

#logout_arrow {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    margin-top: -1px;
}

a {
    text-decoration: none !important;
}

.switch-box {
    padding: 10px 8px;
    font-size: 14px;
}
</style>