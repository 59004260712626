<template>
    <div id="content">
        <div id="container">
            <div>
                <TheLogo id="logo"/>
                <h1>404. Ocorreu um erro.</h1>
                <h2>Página não encontrada</h2>
                <div class="text-end">
                    <small><router-link to="/">Página inicial</router-link></small>
                </div>
            </div>
            <div id="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <!--! Font Awesome Pro 6.1.1 by @fontawesome  - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                        d="M224 0C232.8 0 240 7.164 240 16V64H304C339.3 64 368 92.65 368 128V256C368 291.3 339.3 320 304 320H144C108.7 320 80 291.3 80 256V128C80 92.65 108.7 64 144 64H208V16C208 7.164 215.2 0 224 0zM160 192C177.7 192 192 177.7 192 160C192 142.3 177.7 128 160 128C142.3 128 128 142.3 128 160C128 177.7 142.3 192 160 192zM288 128C270.3 128 256 142.3 256 160C256 177.7 270.3 192 288 192C305.7 192 320 177.7 320 160C320 142.3 305.7 128 288 128zM160 240C151.2 240 144 247.2 144 256C144 264.8 151.2 272 160 272C168.8 272 176 264.8 176 256C176 247.2 168.8 240 160 240zM224 272C232.8 272 240 264.8 240 256C240 247.2 232.8 240 224 240C215.2 240 208 247.2 208 256C208 264.8 215.2 272 224 272zM288 240C279.2 240 272 247.2 272 256C272 264.8 279.2 272 288 272C296.8 272 304 264.8 304 256C304 247.2 296.8 240 288 240zM16 144C16 135.2 23.16 128 32 128C40.84 128 48 135.2 48 144V240C48 248.8 40.84 256 32 256C23.16 256 16 248.8 16 240V144zM416 128C424.8 128 432 135.2 432 144V240C432 248.8 424.8 256 416 256C407.2 256 400 248.8 400 240V144C400 135.2 407.2 128 416 128zM352 352C405 352 448 394.1 448 448V480C448 497.7 433.7 512 416 512H352V448C352 430.3 337.7 416 320 416H128C110.3 416 96 430.3 96 448V512H32C14.33 512 0 497.7 0 480V448C0 394.1 42.98 352 96 352H352zM176 512H144V464C144 455.2 151.2 448 160 448C168.8 448 176 455.2 176 464V512zM224 448C232.8 448 240 455.2 240 464V512H208V464C208 455.2 215.2 448 224 448zM304 512H272V464C272 455.2 279.2 448 288 448C296.8 448 304 455.2 304 464V512z"
                    ></path>
                </svg>
            </div>
        </div>
    </div>
</template>

<style scoped>
#content {
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    background: #f7f7f7;
    color: #414951;
}

#container {
    text-align: left;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
}

#logo {
    width: 180px;
    margin-bottom: 36px;
}

h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 13px;
}

h2 {
    font-weight: 400;
    font-size: 18px;
    color: #808080;
    margin-top: 10px;
}

#icon {
    width: 125px;
    margin-left: auto;
    fill: #d5d5d5;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media  screen and (max-width: 900px){
    #container {
        width: 100%;
        text-align: center;
        display: block;
    }

    #icon {
        margin: 0 auto;
        margin-top: 40px;
    }
    .text-end {
        max-width: 210px;
        margin: 0 auto;
    }
}
</style>