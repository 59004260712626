<template>
    <teleport to="body">
        <div @click="closeDialog" class="backdrop"></div>
        <dialog open>
            <slot></slot>
        </dialog>
    </teleport>
</template>

<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps({
    samePage: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const emit = defineEmits(["closePopup"]);

function closeDialog() {
    document.body.style.overflow = "auto";
    if (props.samePage == false) {
        router.push("/");
    } else {
        emit("closePopup");
    }
}
onMounted(() => {
    document.body.style.overflow = "hidden";
});
</script>


<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0%;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
    cursor: pointer;
}
dialog {
    background-color: #fff;
    position: fixed;
    left: 100%;
    top: 0px;
    width: 550px;
    z-index: 99;
    border: 0;
    height: 100%;
    overflow: auto;
    right: 0;
    left: inherit;
}
.close {
    position: absolute;
    left: 70%;
    width: 25px;
    top: 50%;
    margin-top: -25px;
    margin-left: -30px;
    fill: #fff;
}
.dialog_close {
    display: none;
}
@media screen and (max-width: 1024px) {
    dialog {
        width: 100%;
        left: 0px;
        top: 0px;
        height: 100%;
        margin: 0;
        border-radius: 0px;
        margin: 0;
    }
    .close {
        display: none;
    }
}
</style>
