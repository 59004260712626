<template>
    <TheBaseLogin
    :title="title"
    :subtitle="subtitle"
    >
        <div v-if="inCode">
            <form @submit.prevent="loginWithOutPasswordCode">
                <div class="opt-box">
                    <VOtpInput
                        ref="otpInput"
                        input-classes="otp-input"
                        separator=""
                        :num-inputs="8"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-change="handleCode"
                        @on-complete="handleCode"
                    />
                </div>

                <BaseButton
                    class="mt-4 btn-primary w-100"
                    :loading="isLoading"
                    :disabled="isDisabled"
                    >Entrar
                </BaseButton>
            </form>
        </div>
        <div v-else>
            <form @submit.prevent="loginWithOutPassword">
                <BaseInput
                    label="E-mail"
                    type="email"
                    id="email"
                    v-model="inputs.email"
                    :page="page"
                    login
                />

                <BaseButton
                    class="mt-4 btn-primary w-100"
                    :loading="isLoading"
                    :disabled="isLoading"
                    >Continuar
                </BaseButton>
            </form>

            <p class="spacer text-center mt-5">
                <span>ou</span>
            </p>

            <BaseButton class="mt-2 btn-secondary w-100" to="/login" link
                >Voltar</BaseButton
            >
        </div>
    </TheBaseLogin>
</template>

<script setup>
import TheBaseLogin from "@/components/layout/login/TheLoginBase";
import { ref, watch } from "vue";
import VOtpInput from "vue3-otp-input";

import { useAuthStore } from "@/store/auth";
const auth = useAuthStore();

import { useGlobalStore } from "@/store/global";
const global = useGlobalStore();

const inputs = ref({
    email: '',
    code: '',
    recaptcha_token: ''
});

const page = ref('loginWithOutPassword');
const isLoading = ref(false);

const inCode = ref(false);

const title = ref('Realizar login sem senha');
const subtitle = ref('Digite seu e-mail para iniciar.');

watch(inCode, function (curValue) {
    title.value = (curValue) ? 'Digite o código' : 'Realizar login sem senha';
    subtitle.value = (curValue) ? 'Um código de 8 digitos foi enviado para seu e-mail.' : 'Digite seu e-mail para iniciar.';
});

async function loginWithOutPassword() {
    isLoading.value = false;

    const emailPattern = /^([\w.*-]+@([\w-]+\.)+[\w-]{2,4})?$/;

    if (inputs.value.email === "" || !inputs.value.email.match(emailPattern)) {
        global.tryLocalErrors('email', 'Digite um e-mail válido', page.value);
        return;
    }

    isLoading.value = true;
    // eslint-disable-next-line
    grecaptcha.ready(() => {
        isLoading.value = true;
        // eslint-disable-next-line
        grecaptcha.execute(window.RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {   
            inputs.value.recaptcha_token = token;
            auth.tryRequestLoginWithoutPassword(page.value, inputs.value).then((response) =>{
                if(response) {
                    inCode.value = true;
                    isLoading.value = false;
                }else {
                    inCode.value = false;
                    isLoading.value = false;
                }
                inputs.value.email = '';
                inputs.value.recaptcha_token = '';
            });
        });
    });
}

const isDisabled = ref(true);
const code = ref('');

function handleCode(val) {
    code.value = val;

    if(!val || val.length != 8) {
        isDisabled.value = true;
    }else{
        isDisabled.value = false;
    }
}

async function loginWithOutPasswordCode() {
    if(!isDisabled.value) {
        // eslint-disable-next-line
        grecaptcha.ready(() => {
            isLoading.value = true;
            // eslint-disable-next-line
            grecaptcha.execute(window.RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {   
                inputs.value.recaptcha_token = token;
                inputs.value.code = code.value;
                
                auth.tryLoginWithoutPassword(page.value, inputs.value).then((response) =>{
                    if(response) {
                        inCode.value = false;
                        isLoading.value = false;
                    }
                });
            });
        });
    }
}
</script>

<style scoped>
input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
}

.invalid-feedback {
    font-size: 11px !important;
    margin: 0;
}
</style>