<template>
    <div  class="box p-3 mb-3">
        <h4>Arquivos</h4>

        <ul>
            <li v-for="file in props.files" :key="file.title">
                <div class="mt-2 p-3 lesson_file" @click="tryDownloadFile(file.url, file.title)">
                    <div class="d-flex">
                        <svg class="me-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
                        <div>
                            <h5>{{ file.title }}</h5>
                            <p v-if="file.description" class="mb-1">{{ file.description }}</p>
                            <span v-if="file.size">{{ file.size }}</span>
                            <span v-if="file.expired" class="ms-2 badget red">Expirado</span>
                        </div>
                        <svg class="download_file" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"/></svg>
                    </div>
                </div>
            </li>
        </ul>

    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useGlobalStore } from '@/store/global';
import { useOrderStore } from "@/store/order";

const route = useRoute();

const globalStore = useGlobalStore();
const orderStore = useOrderStore();

const props = defineProps({
    files:{
        required: true,
    },
})

const isDownloading = ref(false);

function tryDownloadFile(url, title){
    if(!isDownloading.value){
        globalStore.loader('show');
        isDownloading.value = true;
        orderStore.tryDownloadFile(url, title, route.name).then(() =>{
            isDownloading.value = false;
            globalStore.loader('hide');
        })
    }
}

</script>

<style scoped>
h4 {
    font-size: 15px !important;
}

ul {
    margin: 0 !important;
    padding: 0 !important;
}

li { 
    list-style: none;
}

.lesson_file {
    width: 100%;
    background: #f4f4f4;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid #d7d7d7;
    color: #595959 !important;
    text-decoration: none !important;
    cursor: pointer;
}

svg {
    width: 24px;
    fill: #595959;
}

span {
    opacity: 0.7;
    font-weight: 400;
}

h5 {
    font-size: 14px !important;
    margin-bottom: 2px !important;
}

.download_file {
    margin-left: auto;
    width: 20px;
}
</style>