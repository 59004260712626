import { defineStore } from "pinia";
import Api from "@/api";
import { useToast, POSITION, TYPE } from "vue-toastification";
const toast = useToast();

import { useAuthStore } from "@/store/auth";

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useGlobalStore = defineStore('global', {

    state: () => ({
        errors: {},
        domain_style: {},
        showToast: true
    }),

    actions: {
        async tryDomain(){
            try{
                let params = { 'site' : null}
                let url = window.location.hostname.split('.');
                if(url[1] == 'fullsale'){
                    document.title = "Fullsale";
                    params['site'] = 'fullsale';
                } else if(url[1] == 'evemaster'){
                    document.title = "Evemaster";
                    params['site'] = 'evemaster';
                } else{
                    document.title = "Fullsale";
                    params['site'] = 'fullsale';
                }
                const response = await Api.post('/api/domain', params);
                this.domain_style = response.data;
                return true;
            } catch (error){
                useGlobalStore().tryIncomingErrors(error)
                return false;
            }
        },

        tryInfoMessage(response) {
            toast(
                response,
                { position: POSITION.BOTTOM_RIGHT, type: TYPE.INFO, timeout: 6000 }
            );
        },

        trySuccessMessage(response) {
            toast(
                response,
                { position: POSITION.BOTTOM_RIGHT, type: TYPE.SUCCESS }
            );
        },

        tryWarningMessage(response) {
            toast(
                response,
                { position: POSITION.BOTTOM_RIGHT, type: TYPE.WARNING }
            );
        },

        tryErrorMessage(response) {
            toast(
                response,
                { position: POSITION.BOTTOM_RIGHT, type: TYPE.ERROR }
            );
        },

        loader(r) {
            if(document.getElementById("loader_body")){
                document.getElementById("loader_body").style.display = (r == 'show') ? 'block' : 'none';
            }
        },

        async tryIncomingErrors(error, page = null) {
            if(!error) {
                useAuthStore().tryLogout();
            }
            let status = error.response.status;
            if(status == 422) {
                let errors = error.response.data.errors;
                for (var key in errors) {
                    let title = key;
                    if(key.includes('.')) {
                        let ex = key.split('.');
                        title = ex[0];
                    }
                    const currentErrors = {
                        ...this.errors[page],
                        [title]: errors[key][0]
                    };
                    this.errors[page] = currentErrors;
                }
            }else if(status == 419 || status == 401) {
                useAuthStore().tryLogout(true);
            }else if (status == 404) {
                this.router.push("/404")
            }else if (status == 429) {
                if(this.showToast) {
                    this.showToast = false;
                    this.tryWarningMessage('Muitas requisições, por favor tente novamente mais tarde.');
                    setTimeout(() => {
                        this.showToast = true;
                    }, 6000);
                }
                
            }else {
                this.router.push("/");
                let s = (status) ? '('+status+')' : '';
                this.tryErrorMessage('Ocorreu um problema com a requisição'+s+', tente novamente.');
            }
        },

        tryLocalErrors(error, text, page) {
            const currentErrors = {
                ...this.errors[page],
                [error]: text
            };
            this.errors[page] = currentErrors;
        },

        getFirstLetterName(n) {
            const userFirstLetterGet = n.split(" ").map(function (s) { return s.charAt(0); }).join('');
            return userFirstLetterGet[0];
        },

        formatDateSimple(value){
            if(!value)  { return }
            const getDate =  value.substring(0, 10);
            const dateParts =  getDate.split('-');                
            return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        },

        getFormatDate(date){
            const getDate =  date.substring(0, 10);
            const dateParts =  getDate.split('-');
            const getHour =  date.substring(11,16);
                
            return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]} às ${getHour}` ;
        },

        getFormatDateLong(date){
            const dateObject =  new Date(date);

            const getDay =  dateObject.getDate();
            const getMonth =  dateObject.toLocaleString('default', { month: 'long' });
            const getYear =  dateObject.getFullYear();

            const getHour =  date.substring(11,16);
                
            return `${getDay} de ${getMonth} de ${getYear} às ${getHour}` ;
        }
    }
});