<template>
  <div class="box p-3 mb-3">
    <div v-if="!shipping_address" class="row">
      <div class="col-sm-6 col-12">
        <p class="mb-1">
          <strong class="d-block">Forma de Pagamento</strong>
          {{ info.payment }}
        </p>
      </div>
      <div class="col-6">
        <p v-if="info.payment == 'Boleto Bancário' && info.expiration_date " class="mb-1">
          <strong class="d-block">Vencimento</strong>
          {{ info.expiration_date }}
        </p>
        <p v-if="info.payment == 'Cartão de Crédito'" class="mb-1 col-sm-6 col-12">
          <strong class="d-block">Parcelas</strong>
          {{ info.installments }}
        </p>

      </div>
    </div>
    <div v-if="shipping_address" class="row">
      <div class="col-sm-6 col-12">
        <p class="mb-1">
          <strong class="d-block">Forma de Pagamento</strong>
          {{ info.payment }}
        </p>
        <p v-if="info.expiration_date" class="mb-1">
          <strong class="d-block">Vencimento</strong>
          {{ info.expiration_date }}
        </p>
        <p v-if="info.payment == 'Cartão de Crédito'" class="mb1-">
          <strong class="d-block">Parcelas</strong>
          {{ info.installments }}
        </p>
      </div>
      <div class="col-sm-6 col-12">
        <p class="mb-1">
          <strong class="d-block">Endereço:</strong>
          {{ shipping_address.address }}, {{ shipping_address.number }}<br />
          {{ shipping_address.neighborhood }}<br />
          {{ shipping_address.city }} - {{ shipping_address.state }},
          {{ shipping_address.postcode }}
        </p>
        <p class="mb-1" v-if="shipping_address.complement">
          <strong class="d-block">Complemento:</strong>
          {{ shipping_address.complement }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shipping_address: {
      required: true,
    },
    info: {
      required: true,
    },
  },
};
</script>

<style>

.box p strong {
    font-weight: 600;
    font-size: 15px;
}

</style>