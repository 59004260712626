<template>
    <TheHeader />
    <div class="w-100">
        <div class="container-fluid">
            <div class="container">
                <div class="mt-2 mb-4">
                    <h2 v-if="userFistName.length > 0">Olá, {{ userFistName }}.</h2>
                    <h2 v-else>Olá, ...</h2>
                    <p>Veja abaixo seus pedidos mais recentes.</p>
                </div>

                <div v-if="ordersList">
                    <div v-for="order in ordersList" :key="order.id" class="box order mb-3">
                        <div class="p-4 pt-0">
                            <strong class="mt-3 d-block">{{ order.store }}</strong>
                            <div v-for="(product, key) in order.products" :key="key" class="mt-4" >
                                <div class="aling">
                                    <div :style="{ backgroundImage: `url(${product.image})` }" class="product_image me-4"></div>
                                    <div>
                                        <strong>{{ product.title }}</strong>
                                        <div v-if="!!product.price_promotional">
                                            <del>{{ product.price }}</del>
                                            {{ product.price_promotional }}
                                        </div>
                                        <div v-else>
                                            {{ product.price }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="p-4 divisor_top">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-4 col-md-3 mb-4 mb-md-0">
                                    <strong class="d-block mb-1">Pedido Nº {{ order.id }}</strong>
                                    <BadgetOrder :order="order" />
                                </div>
                                <div class="col-8 col-md-3">
                                    <strong>Data</strong>
                                    <p class="mb-0">{{ formatDate(order.date) }}</p>
                                </div>
                                <div class="col-4 col-md-3" v-if="order.total">
                                    <strong>Total</strong>
                                    <p class="mb-0">{{ order.total }}</p>
                                </div>
                                <div v-else class="col-4 col-md-3">
                                    <span class="badget" :style="{  backgroundColor: '#28a745' }">{{ 'Gratuito' }}</span>
                                </div>
                                <div class="col-12 col-md-3 text-end">
                                    <BaseButton class="btn-primary d-block mt-4 mt-md-0 w-100 position-relative" @click="goToOrder(order.order_key)">
                                    Ver detalhes
                                    <p v-if="order.refund_notification" class="responded_alert" data-toggle="tooltip" data-placement="top" title="Atualização do reembolso">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 31.1c-141.4 0-255.1 93.12-255.1 208c0 49.62 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734c1.249 3 4.021 4.766 7.271 4.766c66.25 0 115.1-31.76 140.6-51.39c32.63 12.25 69.02 19.39 107.4 19.39c141.4 0 255.1-93.13 255.1-207.1S397.4 31.1 256 31.1zM127.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S145.7 271.1 127.1 271.1zM256 271.1c-17.75 0-31.1-14.25-31.1-31.1s14.25-32 31.1-32s31.1 14.25 31.1 32S273.8 271.1 256 271.1zM383.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S401.7 271.1 383.1 271.1z"/></svg>
                                    </p>
                                    </BaseButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <router-view></router-view>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import TheHeader from "@/components/layout/includes/TheHeader";
import BadgetOrder from "@/components/layout/order/BadgetOrder";

import { useGlobalStore } from "@/store/global";
const globalStore = useGlobalStore()

import { useOrderStore } from "@/store/order";
const orders = useOrderStore();

import { useAuthStore } from "@/store/auth";
const loggedUser = useAuthStore();

const userFistName = computed(function () {
    const userNameArray = loggedUser.name.split(" ");
    return userNameArray[0];
});

const isLoading = ref(true);

onMounted(() => {
    globalStore.loader('show');
    orders.tryOrders().then(() => {
        isLoading.value = false;
        globalStore.loader('hide')
    })
});

const ordersList = computed(function () {
    return orders.orders;
});

import { useRouter } from "vue-router";
const router = useRouter();

function goToOrder(order_key) {
    router.push('/order/' + order_key);
}


function formatDate(d) {
    return globalStore.getFormatDate(d);
}
</script>

<style scoped>
h2 {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    color: #414951;
    font-weight: 600;
}

.box {
    background: #fff;
    border: 1px #d7d7d7 solid;
    border-radius: 8px;
}

.aling {
    display: flex;
    width: 100%;
}

.product_image {
    background-repeat: no-repeat;
    background-position: center;
    width: 45px;
    height: 45px;
    background-size: cover;
    border-radius: 4px;
    border: 1px solid #d7d7d7;
}

del {
    color: #9d9d9d;
}

.divisor_top {
    border-top: 1px #d7d7d7 solid;
}

.responded_alert {
    margin: 0;
    position: absolute;
    right: 30px;
    bottom: 30px;
}

.responded_alert svg {
    width: 25px;
    fill: red;
}

.order:last-child {
    margin-bottom: 0px !important;
}
</style>