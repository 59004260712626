import { defineStore } from "pinia";
import Api from "@/api";
import { useGlobalStore } from "@/store/global";
import { useAuthStore } from "@/store/auth";

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useUserStore = defineStore('user', {

    state: () => ({}),

    actions: {
        async tryEditUser(page, params) {
            try {
                await Api.put("api/user", params, { headers: {'Content-Type': 'application/json'}});
                useAuthStore().email = params.email;
                useAuthStore().name = params.name;
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        }
    }

});
