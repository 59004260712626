<template>
    <div class="title item">
        <BackButtonOrder :backTo="'/order/' + order.order_key"></BackButtonOrder>
            <h2>
                <slot></slot>
            </h2>
    </div>
</template>

<script setup>
import { computed } from "vue";
import BackButtonOrder from "@/components/layout/order/header/BackButtonOrder";

import { useOrderStore } from "@/store/order";
const orders = useOrderStore();

const order = computed(function () {
    return orders.currentOrder;
});


</script>
<style scoped>

.title a{
    margin-right: 10px;
}

.title h2 {
    display: inline-block;
    font-size: 16px;
    margin: 0;
    color: #414951;
    font-weight: 600;
}

.title a :deep(span){
    display: none;
}

</style>