<template>
    <div class="box p-3 mb-3">
        <div class="row">
            <div class="col">
                <p class="mb-1">
                    <strong class="d-block">Pedido com recorrência</strong>
                </p>
                <p class="mb-1">Cartão: **** **** **** {{ recurrence.card_last_digits }}</p>
                <p class="mb-1">Bandeira: {{  recurrence.card_brand }}</p>
                <p class="mb-1">Status: <b v-if="recurrence.status" style="color:#28a745">Ativa</b><b v-else style="color:#c51515">Cancelada</b></p>
                <p class="mb-1" v-if="recurrence.status">Próximo pagamento: {{  global.formatDateSimple(recurrence.next_payment) }}</p>
                <div class="mt-2" v-if="recurrence.status">
                    <BaseButton @click="changeCancelStatus" v-if="!cancelRecurrence" class="btn-danger">Cancelar recorrência</BaseButton>
                    <div class="box p-3 cancel_recurrence" v-else>
                        <p>Tem certeza que deseja cancelar a recorrência?</p>
                        <div class="row">
                            <div class="col">
                                <BaseButton class="btn-primary d-block w-100" @click="changeCancelStatus">Não</BaseButton>
                            </div>
                            <div class="col">
                                <BaseButton class="btn-danger d-block w-100" @click="sendCancelRecurrenceRequest">Cancelar</BaseButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box mt-3">
                    <div class="row">
                        <div class="col-md">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Data</th>
                                            <th>Valor</th>
                                        </tr>
                                        <tr  v-for="(charge, key) in recurrence.charges" :key="key">
                                            <td>{{ global.getFormatDate(charge.date) }}</td>
                                            <td>{{ charge.amount }}</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { useGlobalStore } from '@/store/global';
export default {
    emits: ["cancelRecurrenceRequest"],
    data() {
        return {
            global: useGlobalStore(),
            cancelRecurrence: false
        }
    },
    props: {
        recurrence: {
            required: true,
        }
    },
    methods: {
        changeCancelStatus() {
            this.cancelRecurrence = !this.cancelRecurrence;
        },
        sendCancelRecurrenceRequest() {
            this.$emit('cancelRecurrenceRequest');
        }
    }
}
</script>

<style scoped>
    strong {
        font-weight: 600;
    }
    
    .cancel_recurrence {
        font-weight: 400;
        background: #f8f8f8;
        display: inline-block;
    }

    tr td{
        font-weight: 500;
    }

    .total_price td{
        font-weight: 600!important;
        font-size: 15px;
    }
</style>
