<template>
    <div :class="'mb-2 ' + [!login ? 'form-group' : '']">
        <label v-if="label" :for="id" :class="[login ? 'login-label' : '']+ ' ' + [error && !login ? 'danger-label' : '']">{{ label }}</label>
        
        <div class="position-relative">
            <input
                v-if="!textarea"
                :id="id"
                :type="currentType"
                :class="'form-control base-input ' + [login ? 'login' : ''] + ' ' + [error && !login ? 'danger-border' : '']"
                :required="!!required"
                :value="modelValue"
                @input="updateInput"
                :maxlength="maxlength"
                :disabled="disabled == 1"
                v-maska="mask"
                :placeholder="placeholder"
            />

            <textarea v-else
                :id="id"
                :class="'form-control base-input ' + [login ? 'login' : ''] + ' ' + [error && !login ? 'danger-border' : '']"
                :required="!!required"
                :value="modelValue"
                @input="updateInput"
                rows="4"
                :placeholder="placeholder"
                :disabled="disabled == 1"
                :maxlength="maxlength"
            ></textarea>

            <span class="eye" v-if="type == 'password'" @click="clickEye">
                <svg v-if="currentType == 'password'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/></svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
            </span>

            <span v-if="!!error && login" class="invalid-feedback error_login" :class="[error ? 'd-block' : '']" role="alert"><strong>{{ error }}</strong></span>

            <span v-if="!!error && !login" class="invalid-feedback" :class="[error ? 'd-block' : '']" role="alert">{{ error }}</span>
        </div>
    </div>
</template>

<script>
import { useGlobalStore } from "@/store/global";

export default {
    emits: ["update:modelValue"],
    data() {
        return {
            global: useGlobalStore(),
            currentType: this.$props.type,
        }
    },
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },

        id: {
            type: String,
            required: true
        },

        type: {
            type: String,
            required: false,
            default: 'text'
        },

        required: {
            type: Boolean,
            required: false,
            default: null,
        },

        textarea: {
            type: Boolean,
            required: false,
            default: false,
        },

        modelValue: {
        },

        page: {
            type: String,
            required: true
        },

        login: {
            type: Boolean,
            required: false,
            default: false,
        },

        maxlength: {
            required: false,
            default:null,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        mask: {
            required: false,
            default:null,
        },

        placeholder: {
            required: false,
            default: null,
        },
    },
    methods: {
        updateInput(event) {
            this.$emit("update:modelValue", event.target.value);
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                delete this.global.errors[this.$props.page][this.$props.id];
            }
        },
        clickEye() {
            if(this.$props.type == this.currentType && this.currentType == 'password') {
                this.currentType = 'text';
            }else {
                this.currentType = 'password';
            }
        },
    },
    computed: {
        error() {
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                return this.global.errors[this.$props.page][this.$props.id];
            }
            return null;
        }
    }
};
</script>

<style scoped>
label {
    min-height: 21px;
    display: block;
    margin-block: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    color: #545454;
}

.login-label {
    min-height: 21px;
    display: block;
    margin-bottom: 0.5rem;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
}

.base-input {
    border-radius: 20px;
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 8px 15px;
    box-shadow: none;
    border: 1px solid #e2e2e2;
    font-size: 14px;
    color: #495057;
}

.login {
    border: 2px solid #e2e2e2;
}

.login:focus {
    outline: none !important;
    box-shadow: none;
    border: 2px solid #e2e2e2;
}

.error_login {
    font-size: 11px;
    margin-bottom: 15px;
}

.danger-border {
    border-color: #fd6d6d;
}

.eye{
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    position: absolute;
    top: 9px;
    right: 14px;
}

.eye svg{
    color: #d7d7d7;
    fill: #d7d7d7;
    cursor: pointer;
    height: 1em;
}

.eye svg:hover {
    fill: #545454;
}

.eye-spacing    {
    padding: 8px 40px 8px 15px;
}


.danger-label {
    color: #d60e0e;
}

textarea {
    resize: none;
}
</style>

