<template>
    <div class="box mb-3">
        <div class="row">
            <div class="col-md">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Produto</th>
                                <th>Qtd</th>
                                <th>Valor</th>
                                <th class="row_right">Total</th>
                            </tr>
                            <tr  v-for="(product, key) in products" :key="key">
                                <td>{{ product.title }}</td>
                                <td>{{ product.qty }}</td>
                                <td v-if="product.price_promotional">
                                    <del>{{ product.price }}</del>
                                    {{ product.price_promotional }}
                                </td>
                                <td v-else>{{ product.price }}</td>
                                <td>{{ product.total }}</td>
                            </tr>
                        </thead>

                        <tfoot>
                            <tr>
                                <td colspan="3">Subtotal</td>
                                <td class="row_right">{{ amount.subtotal }}</td>
                            </tr>
                            <tr v-if="shipping">
                                <td colspan="3">Entrega ({{ shipping.title }})</td>
                                <td class="row_right">{{shipping.cost }}</td>
                            </tr>
                            <tr>
                                <td colspan="3">Descontos<span v-if="discount.code"> {{ discount.code }}</span></td>
                                <td class="row_right">{{ discount.discount }}</td>
                            </tr>
                            <tr class="total_price">
                                <td colspan="3">Total</td>
                                <td class="row_right">{{ amount.total }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        products: {
            required: true,
        },
        amount: {
            required: true,
        },
        shipping: {
            required: true,
        },
        discount: {
            required: true,
        }
    }
}
</script>

<style scoped>

tr td{
    font-weight: 500;
}

.total_price td{
    font-weight: 600!important;
    font-size: 15px;
}
</style>