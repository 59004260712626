<template>
    <span
        v-if="order.payment_info && order.payment_info.type == 'pix'"
        class="badget text-center"
        :style="{ backgroundColor: order.status.color }"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            style="margin-right: 5px"
        >
            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M104 103.1C112.8 103.1 120 111.2 120 119.1V135.1C120 144.8 112.8 151.1 104 151.1H88C79.16 151.1 72 144.8 72 135.1V119.1C72 111.2 79.16 103.1 88 103.1H104zM0 79.1C0 53.49 21.49 31.1 48 31.1H144C170.5 31.1 192 53.49 192 79.1V175.1C192 202.5 170.5 223.1 144 223.1H48C21.49 223.1 0 202.5 0 175.1V79.1zM48 175.1H144V79.1H48V175.1zM72 375.1C72 367.2 79.16 359.1 88 359.1H104C112.8 359.1 120 367.2 120 375.1V391.1C120 400.8 112.8 407.1 104 407.1H88C79.16 407.1 72 400.8 72 391.1V375.1zM0 335.1C0 309.5 21.49 287.1 48 287.1H144C170.5 287.1 192 309.5 192 335.1V431.1C192 458.5 170.5 479.1 144 479.1H48C21.49 479.1 0 458.5 0 431.1V335.1zM48 431.1H144V335.1H48V431.1zM360 103.1C368.8 103.1 376 111.2 376 119.1V135.1C376 144.8 368.8 151.1 360 151.1H344C335.2 151.1 328 144.8 328 135.1V119.1C328 111.2 335.2 103.1 344 103.1H360zM400 31.1C426.5 31.1 448 53.49 448 79.1V175.1C448 202.5 426.5 223.1 400 223.1H304C277.5 223.1 256 202.5 256 175.1V79.1C256 53.49 277.5 31.1 304 31.1H400zM400 79.1H304V175.1H400V79.1zM384 479.1H352V447.1H384V479.1zM416 447.1H448V479.1H416V447.1zM448 415.1H352V383.1H320V479.1H256V287.1H352V319.1H416V287.1H448V415.1z"
            />
        </svg>
        {{ order.status.name }}
    </span>
    <span
        v-if="order.payment_info && order.payment_info.type == 'boleto'"
        class="badget text-center"
        :style="{ backgroundColor: order.status.color }"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            style="margin-right: 5px"
        >
            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M112 55.1V191.1H64V55.1C64 42.74 74.75 31.1 88 31.1C101.3 31.1 112 42.74 112 55.1V55.1zM64 455.1V319.1H112V455.1C112 469.3 101.3 479.1 88 479.1C74.75 479.1 64 469.3 64 455.1zM160 463.1V319.1H192V463.1C192 472.8 184.8 479.1 176 479.1C167.2 479.1 160 472.8 160 463.1zM224 455.1V319.1H272V455.1C272 469.3 261.3 479.1 248 479.1C234.7 479.1 224 469.3 224 455.1zM248 31.1C261.3 31.1 272 42.74 272 55.1V191.1H224V55.1C224 42.74 234.7 31.1 248 31.1V31.1zM336 455.1V319.1H384V455.1C384 469.3 373.3 479.1 360 479.1C346.7 479.1 336 469.3 336 455.1zM360 31.1C373.3 31.1 384 42.74 384 55.1V191.1H336V55.1C336 42.74 346.7 31.1 360 31.1V31.1zM464 463.1V319.1H496V463.1C496 472.8 488.8 479.1 480 479.1C471.2 479.1 464 472.8 464 463.1zM480 31.1C488.8 31.1 496 39.16 496 47.1V191.1H464V47.1C464 39.16 471.2 31.1 480 31.1V31.1zM528 455.1V319.1H576V455.1C576 469.3 565.3 479.1 552 479.1C538.7 479.1 528 469.3 528 455.1zM552 31.1C565.3 31.1 576 42.74 576 55.1V191.1H528V55.1C528 42.74 538.7 31.1 552 31.1V31.1zM160 47.1C160 39.16 167.2 31.1 176 31.1C184.8 31.1 192 39.16 192 47.1V191.1H160V47.1zM24 231.1H616C629.3 231.1 640 242.7 640 255.1C640 269.3 629.3 279.1 616 279.1H24C10.75 279.1 0 269.3 0 255.1C0 242.7 10.75 231.1 24 231.1V231.1z"
            />
        </svg>
        {{ order.status.name }}
    </span>
    <span
        v-if="order.payment_info && order.payment_info.type == 'credit-card'"
        class="badget text-center"
        :style="{ backgroundColor: order.status.color }"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            style="margin-right: 5px"
        >
            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M168 336C181.3 336 192 346.7 192 360C192 373.3 181.3 384 168 384H120C106.7 384 96 373.3 96 360C96 346.7 106.7 336 120 336H168zM360 336C373.3 336 384 346.7 384 360C384 373.3 373.3 384 360 384H248C234.7 384 224 373.3 224 360C224 346.7 234.7 336 248 336H360zM512 32C547.3 32 576 60.65 576 96V416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H512zM512 80H64C55.16 80 48 87.16 48 96V128H528V96C528 87.16 520.8 80 512 80zM528 224H48V416C48 424.8 55.16 432 64 432H512C520.8 432 528 424.8 528 416V224z"
            />
        </svg>
        {{ order.status.name }}
        {{ order.payment_info.installments }}
    </span>
</template>

<script>
export default {
    props: {
        order: {
            required: true,
        }
    },
};
</script>

<style scoped>
span svg {
    width: 14px;
    height: 14px;
    fill: white;
    padding-bottom: 2px;
}
</style>