<template>
    <TheBaseLogin
    title="Seja bem vindo"
    subtitle="Digite seus dados para entrar."
    >
        <form @submit.prevent="login">
            <BaseInput
                label="E-mail"
                type="email"
                id="email"
                v-model="inputs.email"
                :page="page"
                login
            />
            <BaseInput
                label="Senha"
                type="password"
                id="password"
                v-model="inputs.password"
                :page="page"
                login
            />

            <div class="text-end">
                <router-link to="/forgot-password"
                    >Esqueceu sua senha?</router-link
                >
            </div>

            <BaseButton
                class="mt-4 btn-primary w-100"
                :loading="isLoading"
                :disabled="isLoading"
                >Entrar
            </BaseButton>
        </form>

        <p class="spacer text-center mt-5">
            <span>ou</span>
        </p>

        <BaseButton
            class="mt-2 btn-primary w-100"
            to="/login-without-password"
            link
            >Login sem senha</BaseButton
        >
    </TheBaseLogin>
</template>

<script setup>
import TheBaseLogin from "@/components/layout/login/TheLoginBase";
import { useAuthStore } from "@/store/auth";
const auth = useAuthStore();

import { useGlobalStore } from "@/store/global";
const global = useGlobalStore();

import { ref, onMounted } from "vue";

const page = ref('login');

onMounted(() => {
    document.body.style.overflow = 'auto';
    delete global.errors[page.value];
});

const inputs = ref({
    email: '',
    password: '',
    recaptcha_token: ''
});

const isLoading = ref(false);

async function login() {
    isLoading.value = false;

    const emailPattern = /^([\w.*-]+@([\w-]+\.)+[\w-]{2,4})?$/;

    if (inputs.value.email === "" || !inputs.value.email.match(emailPattern) ) {
        global.tryLocalErrors('email', 'Digite um e-mail válido', page.value);
        return;
    }

    if (inputs.value.password === "" || inputs.value.password.length < 5) {
        global.tryLocalErrors('password', 'Senha deve conter pelo menos 6 caracteres', page.value);
        return;
    }

    // eslint-disable-next-line
    grecaptcha.ready(() => {
        isLoading.value = true;
        // eslint-disable-next-line
        grecaptcha.execute(window.RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {   
            inputs.value.recaptcha_token = token;             
            auth.tryLogin(page.value, inputs.value).then(() =>{
                isLoading.value = false;
                inputs.value.password = '';
            });
        });
    });
}
</script>