<template>
    <div class="mb-2 form-group">
        <label v-if="label" :class="'label ' + [error ? 'danger-label' : '']">
            {{ label }}
            <svg v-if="info" @click="showInfo" class="info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 400c-18 0-32-14-32-32s13.1-32 32-32c17.1 0 32 14 32 32S273.1 400 256 400zM325.1 258L280 286V288c0 13-11 24-24 24S232 301 232 288V272c0-8 4-16 12-21l57-34C308 213 312 206 312 198C312 186 301.1 176 289.1 176h-51.1C225.1 176 216 186 216 198c0 13-11 24-24 24s-24-11-24-24C168 159 199 128 237.1 128h51.1C329 128 360 159 360 198C360 222 347 245 325.1 258z"/></svg>
        </label>
        <div class="position-relative">
            <input
                :id="id"
                type="file"
                :class="'form-control base-input ' + [error ? 'danger-border' : '']"
                :required="!!required"
                @input="updateInput"
                :multiple="!!multiple"
                :accept="accept"
            />

            <label :for="id" :class="'fake-input ' + [error ? 'danger-border' : '']">{{ fileNames ?? 'Selecione' }}</label>
        </div>

        <span v-if="!!error" class="invalid-feedback" :class="[error ? 'd-block' : '']" role="alert">{{ error }}</span>
    </div>
</template>

<script>
import { useGlobalStore } from "@/store/global";

export default {
    emits: ["update:modelValue"],
    data() {
        return {
            fileNames: null,
            toastIs: true,
            global: useGlobalStore()
        }
    },
    props: {
        modelValue: {},

        label: {
            type: String,
            required: false,
            default: null,
        },

        id: {
            type: String,
            required: true
        },

        required: {
            type: Boolean,
            required: false,
            default: null,
        },

        page: {
            type: String,
            required: true
        },

        multiple: {
            type: Boolean,
            required: false,
            default: null,
        },

        accept: {
            type: String,
            required: false
        },

        info: {
            type: String,
            required: false,
            default: null,
        }
    },
    methods: {
        updateInput(event) {
            let files = event.target.files;
            this.fileNames = null;
            if(files.length > 0) {
                for(var i=0; i<files.length; i++) {
                    this.fileNames = (this.fileNames) ? this.fileNames + ', ' + files[i].name : files[i].name;
                }
            }
            this.$emit("update:modelValue", event.target.files);
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                delete this.global.errors[this.$props.page][this.$props.id];
            }
        },

        showInfo() {
            if(this.toastIs) {
                this.toastIs = false;
                this.global.tryInfoMessage(this.$props.info);
                setTimeout(() => {
                   this.toastIs = true;
                }, 6000);
            }
        }
    },
    computed: {
        error() {
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                return this.global.errors[this.$props.page][this.$props.id];
            }
            return null;
        }
    }
};
</script>

<style scoped>
.label {
    min-height: 21px;
    display: block;
    margin-block: 5px;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    color: #545454;
    z-index: 2;
}

.fake-input {
    border: 1px solid #e2e2e2;
    font-weight: 400;
    padding: 8px 85px 8px 15px;
    border-radius: 20px;
    position: absolute;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fake-input::after {
    content: 'Procurar';
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid #e2e2e2;
    padding: 8px 10px;
    background: #e9ecef;
    cursor: pointer;
}

.base-input {
    border-radius: 20px;
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 8px 15px;
    box-shadow: none;
    border: 1px solid #e2e2e2;
    font-size: 14px;
    color: #495057;
    opacity: 0;
}

.danger-border {
    border-color: #fd6d6d;
    color: #d60e0e;
}

.danger-label {
    color: #d60e0e;
}

.danger-label svg {
    fill: #d60e0e;
}

.info {
    width: 14px;
    fill: #545454;
    cursor: pointer;
}
</style>

